import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import SelectRadioStation from "../../../../components/SelectRadioStation/SelectRadioStation";
import { useSelector } from "react-redux";
import areaApi from "../../../../api/areaApi";
import stationApi from "../../../../api/stationApi";
import _ from "lodash";
import BaseTextField from "../../../../general/custom-fields/BaseTextField";
import { normalizeString } from "../../../../general/utils/Utils";
import ipRadioApi from "api/ipradioApi";

const PrimaryButton = styled.button`
  background-color: #5180fb !important;
  padding: 10px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;

const SpeakerCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 50%;
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    width: 30%;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
  @media (min-width: 1200px) {
    width: 20%;
  }
`;

export default function DevicesPicker({
  disableAll,
  area,
  onAreaChange,
  daiTruyenThanh,
  onDaiTruyenThanhChange,
  speakers,
  onSpeakersChange,
  onSpeakerDataListChange,
}) {
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSelector((state) => state.auth.current);
  const [selectedArea, setSelectedArea] = useState(area);
  const [selectedDaiTruyenThanh, setSelectedDaiTruyenThanh] = useState();
  const [daiTruyenThanhList, setDaiTruyenThanhList] = useState([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [speakerArr, setSpeakerArr] = useState([]);
  const [searchString, setSearchString] = useState("");

  const toggleModal = () => {
    if (disableAll) return;
    if (showModal) {
      setSelectedArea(area);
      setSelectedDaiTruyenThanh(daiTruyenThanh);
    }
    setShowModal(!showModal);
  };

  useEffect(() => {
    let searchStringNormalized = normalizeString(searchString);
    setSpeakerList(
      _.groupBy(
        // search speaker
        searchString
          ? _.filter(speakerArr, function (speaker) {
              return (
                normalizeString(speaker?.TenThietBi).includes(
                  searchStringNormalized
                ) || speaker?.CumLoaID === searchString
              );
            })
          : speakerArr,
        "MaDaiTruyenThanh"
      )
    );
  }, [speakerArr, searchString]);

  useEffect(() => {
    setSelectedArea(area);
  }, [area]);

  useEffect(() => {
    setSelectedDaiTruyenThanh(daiTruyenThanh);
  }, [daiTruyenThanh]);

  useEffect(() => {
    setSelectedSpeakers(speakers);
  }, [speakers]);

  useEffect(async () => {
    let areaId = selectedArea?.MaKhuVuc;
    if (!areaId) return;
    // TODO: get device in area with no pagination
    let speakers = [];
    if (selectedDaiTruyenThanh && selectedDaiTruyenThanh.id) {
      speakers = await stationApi
        .getIpRadioStationDevices({
          MaKhuVuc: selectedArea?.MaKhuVuc,
          MaDaiTruyenThanh: selectedDaiTruyenThanh?.MaDaiTruyenThanh,
          page: 0,
          limit: -1,
          orderField: "id",
          orderMode: "ASC",
        })
        .catch((e) => {
          console.warn(e);
        });
      speakers = speakers.data?.NoiDung?.DanhSachCumLoa;
    } else {
      speakers = await ipRadioApi
        .getDevicesInArea({
          MaKhuVuc: selectedArea?.MaKhuVuc,
          page: 0,
          limit: -1,
          orderField: "id",
          orderMode: "ASC",
        })
        .catch((e) => {
          console.warn(e);
        });
      speakers = speakers.data?.NoiDung?.DanhSachCumLoa;
    }
    if (Array.isArray(speakers)) {
      setSpeakerArr(speakers);
    } else {
      setSpeakerArr([]);
    }
    // areaApi.getDevicesInArea(areaId)
  }, [selectedArea?.MaKhuVuc, selectedDaiTruyenThanh?.id]);

  useEffect(() => {
    if (selectedArea?.MaKhuVuc) {
      stationApi
        .getStations({ MaKhuVuc: selectedArea.MaKhuVuc })
        .then((res) => {
          if (res && res.data && res.data?.MoTa === "ThanhCong") {
            setDaiTruyenThanhList(res.data.NoiDung?.DanhSachDaiTruyenThanh);
          }
        })
        .catch((err) => {});
    }
  }, [selectedArea]);

  return (
    <div>
      <PrimaryButton
        className="w-100"
        onClick={toggleModal}
        disabled={disableAll}
      >
        <i className="far fa-compass" />
        Thiết Bị Sẽ Phát Trong Khu Vực
      </PrimaryButton>
      <Modal
        centered={true}
        isOpen={showModal}
        toggle={toggleModal}
        scrollable={true}
        size={"xl"}
        contentClassName={"h-100"}
        className={"w-100"}
      >
        <ModalHeader
          className="text-center font-weight-boldest justify-content-center"
          style={{ padding: "16px 20px" }}
        >
          Quản lý các thiết bị sẽ phát
        </ModalHeader>
        <ModalBody className="p-0">
          <div style={{ padding: "16px 20px" }} className="d-flex">
            <div
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                flex: 1,
                marginLeft: -8,
              }}
            >
              <span style={{ marginBottom: 4 }}>
                Khu vực <span className="text-danger">*</span>
              </span>
              <SelectArea
                onSelect={(selectArea) => {
                  if (selectArea.MaKhuVuc != selectedArea?.MaKhuVuc) {
                    setSelectedArea(selectArea);
                    setSelectedDaiTruyenThanh(null);
                    setSelectedSpeakers([]);
                  }
                }}
                selectedArea={selectedArea}
                rootAreaId={currentUser.MaKhuVuc}
              />
            </div>

            <div style={{ paddingLeft: 8, paddingRight: 8, flex: 1 }}>
              <span style={{ marginBottom: 4 }}>Đài Truyền Thanh</span>
              <SelectRadioStation
                noBorder={false}
                onSelect={(daiTruyenThanh) => {
                  if (daiTruyenThanh.id != selectedDaiTruyenThanh?.id) {
                    setSelectedDaiTruyenThanh(daiTruyenThanh);
                    setSelectedSpeakers([]);
                  }
                }}
                MaKhuVuc={selectedArea?.MaKhuVuc}
                selectedRadioStation={selectedDaiTruyenThanh}
              />
            </div>

            <div
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                flex: 1,
                marginRight: -8,
              }}
            >
              <span style={{ marginBottom: 4 }}>Tìm Kiếm</span>
              <BaseTextField
                className={"mb-3"}
                name={"device-search"}
                placeholder={"Nhập tên/mã thiết bị"}
                fieldProps={{
                  value: searchString,
                  onChange: (e) => setSearchString(e.target.value),
                }}
              />
            </div>
          </div>
          <div
            style={{ padding: "0 20px 8px" }}
            className={`border-top ${!_.isEmpty(speakerList) ? "" : "d-none"}`}
          >
            {Object.keys(speakerList).map((maDaiTruyenThanh, index) => {
              let speakers = speakerList[maDaiTruyenThanh],
                speakerIds = [];
              let chooseAll = true;
              let speakerView = speakers.map((speaker) => {
                speakerIds.push(speaker?.id);

                let speakerActiveIndex = _.findIndex(selectedSpeakers, {
                    id: speaker?.id,
                  }),
                  speakerActive = speakerActiveIndex !== -1;
                if (!speakerActive) chooseAll = false;
                // chọn 1 loa cụ thể
                return (
                  <SpeakerCheckboxContainer key={speaker?.id}>
                    <label
                      className="custom-checkbox d-flex align-items-center"
                      style={{ marginBottom: 8 }}
                    >
                      <input
                        type="checkbox"
                        value={speaker?.id}
                        checked={speakerActive}
                        onChange={() => {
                          let currentSelectedSpeakers = [...selectedSpeakers];
                          if (speakerActive) {
                            currentSelectedSpeakers.splice(
                              speakerActiveIndex,
                              1
                            );
                          } else {
                            currentSelectedSpeakers.push(speaker);
                          }
                          setSelectedSpeakers(currentSelectedSpeakers);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div
                      className="Regular_13"
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {speaker?.TenThietBi}
                    </div>
                  </SpeakerCheckboxContainer>
                );
              });
              return (
                <div key={maDaiTruyenThanh}>
                  <div
                    style={{ paddingBottom: 10, paddingTop: 16 }}
                    className="Bold_13 border-bottom d-flex align-items-center"
                  >
                    <label className="Regular_13 custom-checkbox">
                      <input
                        type="checkbox"
                        value={maDaiTruyenThanh}
                        checked={chooseAll}
                        onChange={() => {
                          // select all / unselect all
                          let currentSelectedSpeakers = [...selectedSpeakers];
                          if (chooseAll) {
                            // filter tất cả các loa đã chọn
                            currentSelectedSpeakers =
                              currentSelectedSpeakers.filter(
                                ({ id: speakerId }) =>
                                  !speakerIds.includes(speakerId)
                              );
                          } else {
                            currentSelectedSpeakers.push(...speakers);
                          }
                          setSelectedSpeakers(currentSelectedSpeakers);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <span
                      style={{
                        marginLeft: 10,
                      }}
                    >
                      {
                        daiTruyenThanhList?.find(
                          (daiTruyenThanh) =>
                            daiTruyenThanh?.MaDaiTruyenThanh ===
                            speakers?.[0]?.["MaDaiTruyenThanh"]
                        )?.Ten
                      }
                    </span>
                  </div>
                  <div
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    className="d-flex flex-wrap"
                  >
                    {speakerView}
                  </div>
                </div>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: "10px 20px" }}>
          <div className="d-flex m-0 w-100">
            <div style={{ paddingLeft: 0, paddingRight: 8 }} className="col">
              <button
                onClick={toggleModal}
                style={{ height: 40 }}
                className="btn btn-secondary border w-100"
              >
                Hủy
              </button>
            </div>

            <div style={{ paddingLeft: 8, paddingRight: 0 }} className="col">
              <PrimaryButton
                onClick={() => {
                  onAreaChange(selectedArea);
                  onDaiTruyenThanhChange(selectedDaiTruyenThanh);
                  onSpeakersChange(
                    selectedSpeakers?.map((speaker) => {
                      return {
                        ...speaker,
                        TenDaiTuyenThanh:
                          daiTruyenThanhList?.find(
                            (daiTruyenThanh) =>
                              daiTruyenThanh?.MaDaiTruyenThanh ===
                              speaker?.MaDaiTruyenThanh
                          )?.Ten || "",
                      };
                    })
                  );
                  toggleModal();
                }}
                style={{ height: 40 }}
                className={"w-100"}
              >
                Áp Dụng
              </PrimaryButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
