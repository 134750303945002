import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AppPage from "../../../../components/AppPage";
import { AppImages } from "../../../../general/constants/AppResource";
import NavBar from "../../../../components/NavBar";
import AppCard from "../../../../components/AppCard";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import BaseSearchBar from "../../../../general/custom-fields/BaseSearchBar";
import { useHistory } from "react-router-dom";
import newsApi from "api/newsApi";
import RadioBulletinTable from "components/RadioBulletinTable";

export default function BulletinListScreen() {
  const { t } = useTranslation();
  const history = useHistory();

  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);
  const [selectedArea, setSelectedArea] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  // MARK: --- Functions ---
  // Recursive Func to get Breadcrumb

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.MaKhuVuc > 0 &&
      selectedArea?.MaKhuVuc != currentUser.MaKhuVuc
    ) {
      setSelectedArea(
        findAreaById(currentUser.MaKhuVuc ? currentUser.MaKhuVuc : -1)
      );
    }
  }, [currentUser, allAreas]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackgroundEBulletin}
        headerTitle={t("BulletinManager")}
      >
        <NavBar navBarTitle={t("BulletinManager")}></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            className="bg-white"
            title="Danh sách bản tin"
            buttonText={t("Thêm Bản Tin")}
            clickAdd={() => {
              history.push("/bulletins/edit/speaker");
            }}
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setSearchText}
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-300px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      setSelectedArea(area);
                    }}
                    rootAreaId={currentUser.MaKhuVuc}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>
              </>
            }
          >
            <RadioBulletinTable
              getEBulletinsAPI={(params) => {
                let MaKhuVuc = selectedArea
                  ? selectedArea.MaKhuVuc
                  : currentUser.MaKhuVuc;
                if (MaKhuVuc) {
                  return newsApi.getBulletinList({ MaKhuVuc, ...params });
                }
              }}
              currentAreaId={
                selectedArea?.MaKhuVuc || currentUser?.MaKhuVuc || null
              }
              searchText={searchText}
              selectedItems={selectedItems}
              onSelectedItemsChange={setSelectedItems}
            />
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}
