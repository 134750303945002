import axiosClient from "./axiosClient";
import _ from "lodash";

const newsApi = {
  getBulletinList: (params) => {
    const url = `/news/ipradio`;
    return axiosClient.get(url, { params });
  },
  createIpRadioBulletin: (params) => {
    const url = `/news/ipradio/create`;
    return axiosClient.post(url, params);
  },
  createDigitalsignageBulletin: (params) => {
    const url = `/news/digitalsignage/create`;
    return axiosClient.post(url, params);
  },
  createIpRadioEmergencyBulletin: (params) => {
    const url = `/news/ipradio/emergency`;
    return axiosClient.post(url, params);
  },
  createDigitalsignageEmergencyBulletin: (params) => {
    const url = `/news/digitalsignage/emergency`;
    return axiosClient.post(url, params);
  },
  uploadFile: (files, type) => {
    const url = `/news/upload`;
    const formData = new FormData();
    if (_.isArray(files)) {
      files.forEach((file, index) => {
        formData.append(`Files`, file);
      });
    } else {
      formData.append("Files", files);
    }
    formData.append("type", type);
    return axiosClient.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getDigitalsignageBulletinList: (params) => {
    const url = `/news/digitalsignage`;
    return axiosClient.get(url, { params });
  },
};

export default newsApi;
