import newsApi from "api/newsApi";
import { buildURL, getFileDuration } from "general/utils/Utils";
import moment from "moment";
import _ from "lodash";

export const BulletinHelper = {
  /**
   * Lấy danh sách ngày phát
   * @param {moment.Moment} startDate
   * @param {moment.Moment} endDate
   * @param {Array<{startTime:moment.Moment,endTime:moment.Moment}>} playTime
   * @param {string} mode
   * @returns
   */
  getDanhSachNgayPhat: (startDate, endDate, playTime, mode) => {
    const result = [];
    const ThoiDiemPhat = playTime?.map((ptime) => {
      return {
        ThoiGianBatDau: moment(ptime.startTime).valueOf(),
        ThoiGianKetThuc: moment(ptime.endTime).valueOf(),
      };
    });
    for (let i = 0; i <= moment(endDate).diff(moment(startDate), "day"); i++) {
      switch (mode) {
        case "FIX":
          result.push({
            NgayPhat: moment(startDate).add(i, "days").unix(),
            ThoiDiemPhat,
          });

          break;
        case "SCHEDULE":
          if (repeatType === "Month") {
            const playingDayArray =
              playingDay?.map((day) => moment(day).date()) || [];
            if (
              playingDayArray?.includes(moment(startDate).add(i, "days").date())
            )
              result.push({
                NgayPhat: moment(startDate).add(i, "days").unix(),
                ThoiDiemPhat,
              });
          } else if (repeatType === "Week") {
            if (
              playingWeekdays?.includes(
                moment(startDate).add(i, "days").day() + 1
              )
            ) {
              result.push({
                NgayPhat: moment(startDate).add(i, "days").unix(),
                ThoiDiemPhat,
              });
            }
          }
        default:
          break;
      }
    }

    return result;
  },

  /**
   * Upload audio files
   * @param {object} filesUpload
   * @returns
   */
  handleUploadFile: async (filesUpload, type) => {
    if (filesUpload.length === 0) {
      return "";
    }
    const resUploadFiles = await newsApi.uploadFile(
      filesUpload?.map((file) => file?.file),
      type
    );
    if (
      resUploadFiles?.data &&
      resUploadFiles?.data?.MoTa === "ThanhCong" &&
      resUploadFiles?.data?.NoiDung?.path
    ) {
      return resUploadFiles?.data?.NoiDung?.path;
    }
    return "";
  },

  /**
   * Lay noi dung
   * @param {string} sourceType
   * @param {boolean} playDirectStatus
   * @param {object} files
   * @param {object} recordFiles
   * @param {object} ttsData
   * @returns
   */
  getNoiDung: async (
    sourceType,
    playDirectStatus,
    files,
    recordFiles,
    ttsData
  ) => {
    switch (sourceType) {
      case "MIC":
        return playDirectStatus
          ? "RTMP"
          : await BulletinHelper.handleUploadFile(recordFiles, "IPRADIO");
      case "FILE":
        return await BulletinHelper.handleUploadFile(files, "IPRADIO");
      case "DOCUMENT":
        return buildURL(ttsData?.audioContent);
      default:
        break;
    }
    return "";
  },

  /**
   * Lay noi dung ban tin dien tu
   * @param {string} sourceType
   * @param {object} files
   * @param {object} recordFiles
   * @param {object} ttsData
   * @returns
   */
  getNoiDungBanTinDienTu: async (sourceType, images, videos, text) => {
    switch (sourceType) {
      case "IMAGE":
        await BulletinHelper.handleUploadFile(images, "DIGITALSIGNAGE");
      case "VIDEO":
        return await BulletinHelper.handleUploadFile(videos, "DIGITALSIGNAGE");
      case "TEXT":
        return text;
      default:
        break;
    }
    return "";
  },

  /**
   * Lay thoi luong
   * @param {string} sourceType
   * @param {object} files
   * @param {object} recordFiles
   * @returns
   */
  getDuration: async (sourceType, files, recordFiles, ttsData) => {
    let duration = 0;
    if (sourceType === "DOCUMENT") {
      const resDuration = await getFileDuration(buildURL(ttsData.audioContent));
      duration = resDuration?.duration || 0;
    } else {
      const filesArray =
        sourceType === "FILE" ? files : sourceType === "MIC" ? recordFiles : "";
      if ((!filesArray, !filesArray.length)) {
        return "00:00:00";
      }
      duration = filesArray.reduce(
        (duration, file) => (duration += file.duration),
        0
      );
    }

    return [
      parseInt(duration / 3600),
      parseInt((duration % 3600) / 60),
      parseInt((duration % 3600) % 60),
    ]
      .map((item) =>
        item.toString().length === 1 ? `0${item.toString()}` : item.toString()
      )
      .join(":");
  },

  /**
   *  Lay danh sach dia ban nhan
   * @param {Array<object>} speakers
   * @param {Array<object>} listArea
   * @returns
   */
  getDanhSachDiaBanNhan: (speakers, listArea) => {
    return _.uniqBy(
      speakers?.map((speaker) => {
        return {
          DichID: speaker?.MaKhuVuc,
        };
      }),
      "DichID"
    )?.map((dich) => {
      return {
        ...dich,
        TenDich: listArea?.find((area) => area?.MaKhuVuc == dich?.DichID)?.Ten,
      };
    });
  },

  /**
   * Lay danh sach dia ban nhan ban tin phat thanh khan cap
   * @param {Array<object>} speakers
   * @param {Array<object>} listArea
   * @returns
   */
  getDanhSachDiaBanNhanBanTinKhanCap: (speakers, listArea) => {
    return _.uniqBy(
      speakers?.map((speaker) => {
        return {
          DichID: speaker?.MaKhuVuc,
        };
      }),
      "DichID"
    )?.map((dich) => {
      return {
        ...dich,
        TenDich: listArea?.find((area) => area?.MaKhuVuc == dich?.DichID)?.Ten,
        CumLoaID: speakers
          ?.filter((speaker) => speaker?.MaKhuVuc === dich?.DichID)
          ?.map((item) => item?.CumLoaID),
      };
    });
  },

  /**
   * Lay danh sach dia ban nhan ban tin dien tu khan cap
   * @param {Array<object>} digitalSignages
   * @param {Array<object>} listArea
   * @returns
   */
  getDanhSachDiaBanNhanBanTinDienTuKhanCap: (digitalSignages, listArea) => {
    return _.uniqBy(
      digitalSignages?.map((digitalsignage) => {
        return {
          DichID: digitalsignage?.MaKhuVuc,
        };
      }),
      "DichID"
    )?.map((dich) => {
      return {
        ...dich,
        TenDich: listArea?.find((area) => area?.MaKhuVuc == dich?.DichID)?.Ten,
        BangTinDienTuID: digitalSignages
          ?.filter(
            (digitalsignage) => digitalsignage?.MaKhuVuc === dich?.DichID
          )
          ?.map((item) => item?.BangTinDienTuID),
      };
    });
  },
};
