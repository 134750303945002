import areaApi from "api/areaApi";
import customDataTableStyle from "assets/styles/customDataTableStyle";
import ModalDelete from "components/AppModal/ModalDelete";
import AppPage from "components/AppPage";
import AppPagination from "components/AppPagination";
import Empty from "components/Empty";
import Loading from "components/Loading";
import Global from "general/Global";
import { AppIcons, AppImages } from "general/constants/AppResource";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import ToastHelper from "general/helpers/ToastHelper";
import { findAreaByMaKhuVuc, findNode } from "general/utils/Utils";
import _ from "lodash";
import { setCurrentArea, thunkGetAreas } from "pages/AreaManager/areaSlice";
import ModalDeletedIndicator from "pages/AreaManager/components/ModalDeletedIndicator";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ModalCreateArea from "../../components/ModalCreateArea";
import SelectArea from "components/SelectArea/SelectArea";

AreaManagerHomeScreen.propTypes = {};

function AreaManagerHomeScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);
  const [modalCreateAreaShowing, setModalCreateAreaShowing] = useState(false);
  const { allAreas, currentArea } = useSelector((state) => state.area);
  const [arrBreadcrumb, setArrBreadcrumb] = useState([]);
  const [selectedAreaItem, setSelectedAreaItem] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rootDataItems, setRootDataItems] = useState(allAreas);
  const [dataItems, setDataItems] = useState(rootDataItems);
  const [parentArea, setParentArea] = useState(null);
  const [toggleClearAreas, setToggledClearAreas] = useState(true);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const [modalDeletedIndicatorShowing, setModalDeletedIndicatorShowing] =
    useState(false);

  const [deletedCounting, setDeletedCounting] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 30,
    searchText: "",
  });
  const columns = useMemo(
    () => [
      {
        name: t("List"),
        cell: (row) => {
          return (
            <div
              className="d-flex flex-row align-items-center position-relative"
              style={{ minWidth: 120 }}
            >
              {row?.MaKhuVuc?.split(".")?.[2] === "00" ? (
                <i className="fas fa-folder text-primary mr-2"></i>
              ) : (
                <img src={AppIcons.icFolderShare} className="mr-2" />
              )}

              <p
                data-tag="allowRowEvents"
                className=" m-0 text-maxline-3 mr-4"
                style={{ color: "rgba(81, 128, 251, 1)", fontWeight: "600" }}
              >
                {row?.Ten}
              </p>
            </div>
          );
        },
      },
      {
        name: t("NumberOfDevices"),
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.SoThietBi}
            </p>
          );
        },
      },
      {
        name: t("Provider"),
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.DangHoatDong}
            </p>
          );
        },
      },
      {
        name: t("Active"),
        cell: (row) => {
          return (
            <p
              data-tag="allowRowEvents"
              className=" font-weight-normal m-0 text-maxline-3 mr-4"
            >
              {row?.DangHoatDong}
            </p>
          );
        },
      },
      {
        name: t("Manage"),
        center: true,
        width: "100px",
        style: {
          paddingRight: "0px",
        },
        cell: (row) => (
          <div className="d-flex align-items-center justify-content-between">
            <i
              className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
              onClick={() => {
                setSelectedAreaItem(row);
                setModalCreateAreaShowing(true);
              }}
            ></i>
            <i
              className="fa fa-trash-alt cursor-pointer text-hover-danger"
              onClick={() => {
                setSelectedAreaItem(row);
                setModalDeleteShowing(true);
              }}
            ></i>
          </div>
        ),
      },
    ],
    [allAreas]
  );

  // MARK: --- Functions ---
  async function getAreas() {
    setIsLoading(true);
    dispatch(thunkGetAreas());
    setIsLoading(false);
  }

  function handleClickedArea(area) {
    setParentArea(area);
    // setSelectedAreaItem(area);
    setRootDataItems(area.childrens ?? []);
    dispatch(setCurrentArea(area));
    setArrBreadcrumb([
      ...arrBreadcrumb,
      {
        id: area.id,
        name: area.Ten,
        items: area.childrens,
        MaKhuVuc: area.MaKhuVuc,
      },
    ]);
    clearSelectedAreas();
  }

  function clearSelectedAreas() {
    setSelectedAreas([]);
    setToggledClearAreas(!toggleClearAreas);
  }

  function onRefresh() {
    getAreas();
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    getAreas();
  }, []);
  console.log("arrBreadcrumb", arrBreadcrumb);
  useEffect(() => {
    const { page, limit } = filters;
    const text = _.lowerCase(filters.searchText);
    let filteredItems = _.filter(rootDataItems, function (item) {
      return _.lowerCase(_.lowerCase(item.Ten)).includes(text);
    });

    if ((page, limit)) {
      if (page * limit <= filteredItems?.length) {
        filteredItems = filteredItems.slice((page - 1) * limit, page * limit);
      } else {
        filteredItems = filteredItems.slice(
          (page - 1) * limit,
          filteredItems?.length
        );
      }
    }

    setDataItems(filteredItems);
  }, [filters, rootDataItems]);

  useEffect(() => {
    if (currentArea) {
      // const updatedCurrentArea = findNode(allAreas, currentArea.MaKhuVuc);
      // setRootDataItems(updatedCurrentArea.childrens);
      setParentArea(currentArea);

      setRootDataItems(currentArea?.childrens ?? []);
      dispatch(setCurrentArea(currentArea));

      if (currentArea?.MaKhuVuc?.split(".")?.[2] != "00") {
        let parent = findAreaByMaKhuVuc(currentArea?.MaKhuVucCha);

        setArrBreadcrumb([
          {
            id: parent?.id,
            name: parent?.Ten,
            items: parent?.childrens,
          },
          {
            id: currentArea.id,
            name: currentArea.Ten,
            items: currentArea.childrens,
          },
        ]);
      } else if (currentArea?.MaKhuVuc?.split(".")?.[1] != "00") {
        setArrBreadcrumb([
          {
            id: currentArea.id,
            name: currentArea.Ten,
            items: currentArea.childrens,
          },
        ]);
      } else {
        setArrBreadcrumb([]);
      }
      clearSelectedAreas();
    } else {
      // setRootDataItems(allAreas);

      setParentArea(allAreas?.[0]);
      // setSelectedAreaItem(area);
      setRootDataItems(allAreas?.[0]?.childrens ?? []);
      dispatch(setCurrentArea(allAreas?.[0]));
      // setArrBreadcrumb([
      //   ...arrBreadcrumb,
      //   {
      //     id: allAreas?.[0]?.id,
      //     name: allAreas?.[0]?.Ten,
      //     items: allAreas?.[0]?.childrens,
      //   },
      // ]);

      clearSelectedAreas();
    }

    // setArrBreadcrumb([]);
  }, [allAreas]);

  useEffect(() => {
    setDataItems(rootDataItems);
  }, []);

  useEffect(() => {
    if (selectedAreas.length === deletedCounting && deletedCounting > 0) {
      setTimeout(() => {
        setModalDeletedIndicatorShowing(false);
        ToastHelper.showSuccess(t("DeletedAreaSuccessfully"));
        dispatch(thunkGetAreas());
        setSelectedAreas([]);
        setDeletedCounting(0);
        clearSelectedAreas();
      }, [1000]);
    }
  }, [deletedCounting]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.areaBackground}
        headerTitle={t("AreaManagement")}
      >
        <div className="card card-custom">
          {/* card header */}

          <div className="card-header border-0 px-5 py-3">
            <div className="w-100 d-flex justify-content-between flex-wrap gap-2">
              <div className="card-title my-0 d-flex">
                <h3
                  onClick={() => {
                    // setParentCompanyUnit({});
                    // setRootDataItems(companyUnits);
                    // setArrBreadcrumb([]);
                  }}
                  className="card-label "
                >
                  {arrBreadcrumb?.length > 0 ? (
                    <div className="d-flex flex-wrap">
                      {arrBreadcrumb?.map((item, index) => (
                        <div className="d-flex">
                          <p
                            className=" mr-1 flex-shrink-0"
                            style={{
                              color: "rgba(81, 128, 251, 1)",
                              fontWeight: "600",
                            }}
                          >
                            {item?.name}{" "}
                          </p>{" "}
                          <span className="px-1"> /</span>
                        </div>
                      ))}
                      <div className="">
                        Danh sách thiết bị{" "}
                        <span
                          style={{
                            color: "rgba(81, 128, 251, 1)",
                            fontSize: "14px",
                          }}
                        >
                          ( 0 thiết bị )
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {t("ManagementSystem")}{" "}
                      <span style={{ color: "rgba(81, 128, 251, 1)" }}>
                        {allAreas?.[0]?.Ten}
                      </span>
                    </>
                  )}
                </h3>
              </div>

              {/* header toolbar */}
              <div
                className="card-toolbar flex-grow-1 justify-content-md-end "
                style={{ gap: "10px" }}
              >
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-300px mt-4 mt-md-0 px-smaller-md-0 pr-md-0 mr-3 ">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      // setSelectedArea(area);
                      setParentArea(area);

                      setRootDataItems(area.childrens ?? []);
                      dispatch(setCurrentArea(area));

                      if (area?.MaKhuVuc?.split(".")?.[2] != "00") {
                        let parent = findAreaByMaKhuVuc(area?.MaKhuVucCha);

                        setArrBreadcrumb([
                          {
                            id: parent?.id,
                            name: parent?.Ten,
                            items: parent?.childrens,
                          },
                          {
                            id: area.id,
                            name: area.Ten,
                            items: area.childrens,
                          },
                        ]);
                      } else if (area?.MaKhuVuc?.split(".")?.[1] != "00") {
                        setArrBreadcrumb([
                          {
                            id: area.id,
                            name: area.Ten,
                            items: area.childrens,
                          },
                        ]);
                      } else {
                        setArrBreadcrumb([]);
                      }

                      // setArrBreadcrumb([
                      //   ...arrBreadcrumb,
                      //   {
                      //     id: area.id,
                      //     name: area.Ten,
                      //     items: area.childrens,
                      //   },
                      // ]);
                      clearSelectedAreas();
                    }}
                    rootAreaId={currentUser.MaKhuVuc}
                    selectedArea={parentArea}
                    className="flex-grow-1"
                  />
                </div>
                <BaseSearchBar
                  name="searchBar"
                  placeholder={t("Search")}
                  onSubmit={(value) => {
                    setFilters({
                      ...filters,
                      searchText: value,
                    });
                  }}
                  className="w-md-200px w-xl-250px w-xxxl-300px mr-6"
                />

                <button
                  className="CardButton d-flex flex-row align-items-center"
                  onClick={() => {
                    setSelectedAreaItem(null);
                    setModalCreateAreaShowing(true);
                  }}
                >
                  <i className="far fa-plus-circle" />
                  <p className="Bold_13 text-white text-capitalize m-0">
                    {t("AddArea")}
                  </p>
                </button>
              </div>
            </div>
            {!!selectedAreas.length && (
              <button
                className="btn btn-light-danger mr-2 d-flex flex-row align-items-center mt-6"
                onClick={(e) => {
                  e.preventDefault();
                  setModalDeleteShowing(true);
                }}
              >
                <i className="fas fa-folder"></i>
                <p
                  className="font-weight-bold text-capitalize m-0"
                  style={{ fontSize: 13 }}
                >
                  {`${t("Delete")} ${selectedAreas.length}`}
                </p>
              </button>
            )}
          </div>
          {/* card body */}
          <div className="card-body px-0 pt-0 pb-4">
            <DataTable
              columns={columns}
              data={dataItems}
              customStyles={customDataTableStyle}
              responsive={true}
              noHeader
              selectableRows={true}
              striped
              noDataComponent={
                <div className="d-flex align-items-center justify-content-center py-10 bg-white">
                  <Empty
                    iconEmpty={AppIcons.icEmptyPack}
                    description={t("NoDataToDisplay")}
                    onClickButton={onRefresh}
                  />
                </div>
              }
              progressPending={isLoading}
              progressComponent={<Loading message={`${t("Loading")}...`} />}
              onSelectedRowsChange={(state) => {
                setSelectedAreas(state.selectedRows);
              }}
              clearSelectedRows={toggleClearAreas}
              onRowClicked={(row) => {
                handleClickedArea(row);
              }}
              pointerOnHover
              highlightOnHover
              selectableRowsHighlight
            />

            {/* Pagination */}
            {dataItems && dataItems.length > 0 && (
              <AppPagination
                className="bg-white"
                total={rootDataItems.length}
                step={Global.gAvailablePagination}
                curStep={filters.limit}
                curPage={filters.page}
                setPage={(newPage) => {
                  setFilters({
                    ...filters,
                    page: newPage,
                  });
                }}
                setStep={(newLimit) => {
                  setFilters({
                    ...filters,
                    limit: newLimit,
                  });
                }}
              />
            )}
          </div>
        </div>
      </AppPage>

      <ModalCreateArea
        editableArea={selectedAreaItem}
        show={modalCreateAreaShowing}
        parentArea={parentArea}
        onClose={() => {
          setModalCreateAreaShowing(false);
          setSelectedAreaItem(null);
        }}
      />

      <ModalDelete
        show={modalDeleteShowing}
        text={
          selectedAreas.length > 0
            ? `Bạn có chắc muốn xóa ${selectedAreas.length} khu vực không?`
            : `Bạn có chắc muốn xóa khu vực ${selectedAreaItem?.Ten} không?`
        }
        onClose={() => setModalDeleteShowing(false)}
        onSubmit={async () => {
          if (selectedAreas.length === 0) {
            const res = await areaApi.deleteArea(selectedAreaItem.MaKhuVuc);
            const { MoTa, NoiDung } = res.data;

            if (MoTa === "ThanhCong") {
              ToastHelper.showSuccess(t("DeletedAreaSuccessfully"));
              dispatch(thunkGetAreas());
              setSelectedAreas([]);
              clearSelectedAreas();
            }
          } else {
            setModalDeletedIndicatorShowing(true);
            selectedAreas.forEach(async (item) => {
              const response = await areaApi.deleteArea(item.MaKhuVuc);
              const { MoTa, NoiDung } = response.data;

              if (MoTa === "ThanhCong") {
                setDeletedCounting((prev) => prev + 1);
              }
            });
          }
        }}
      />

      <ModalDeletedIndicator
        items={selectedAreas}
        deletedItemCount={deletedCounting}
        title={`Đang xóa ${selectedAreas.length} khu vực`}
        show={modalDeletedIndicatorShowing}
        onClose={() => setModalDeletedIndicatorShowing(false)}
      />
    </>
  );
}

export default AreaManagerHomeScreen;
