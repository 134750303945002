import axiosClient from "./axiosClient";

const playlistApi = {
  getBulletinPlaylisList: (params) => {
    const url = `/playlist/ipradio`;
    return axiosClient.get(url, { params });
  },
  getDigitalSignageBulletinPlaylisList: (params) => {
    const url = `/playlist/digitalsignage`;
    return axiosClient.get(url, { params });
  },
  createIpRadioBulletinPlaylist: (params) => {
    const url = `/playlist/ipradio/create`;
    return axiosClient.post(url, params);
  },
  createDigitalSignageBulletinPlaylist: (params) => {
    const url = `/playlist/digitalsignage/create`;
    return axiosClient.post(url, params);
  },
};

export default playlistApi;
