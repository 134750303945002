import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import classes from "./SelectRadioStation.module.scss";
import PropTypes from "prop-types";
import {
  findAreaById,
  getAreaJSTreeStructure,
  normalizeString,
} from "general/utils/Utils";
import store from "libs/storage/store";
import SelectRadioStationItem from "./SelectRadioStationItem";
import stationApi from "api/stationApi";

const mapStateToProps = (state) => {
  return {
    // area: store.getState().area.allAreas,
  };
};

const mapDispatchToProps = {};

class SelectRadioStation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownHeight: 0,
      searchString: null,
      MaKhuVuc: props.MaKhuVuc,
      dropdownStyle: null,
      radioStationLists: [],
    };

    this.inputDebounceTimeout = null;
    this.filterRadioStation = this.filterRadioStation.bind(this);
    this.onSelectRadioStation = this.onSelectRadioStation.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.wrapperRef = React.createRef();
    this.divRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.calculateDropdownHeight = this.calculateDropdownHeight.bind(this);
    this.setDropdownHeight = this.setDropdownHeight.bind(this);
  }

  componentDidMount() {
    if (this.props.MaKhuVuc) {
      this.setState({ MaKhuVuc: this.props.MaKhuVuc });
      stationApi
        .getStationsByMaKhuVuc(this.props.MaKhuVuc)
        .then((data) => {
          console.log("get Station by MaKhuVuc", data);
          if (data && data.data.MoTa == "ThanhCong") {
            this.setState({
              radioStationLists: data.data.NoiDung?.DanhSachDaiTruyenThanh,
            });
            if (data.data.NoiDung?.DanhSachDaiTruyenThanh?.length == 1) {
              this.props.onSelect(data.data.NoiDung?.DanhSachDaiTruyenThanh[0]);
            }
          }
        })
        .catch();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.dropdownHeight &&
      this.state.dropdownHeight !== this.calculateDropdownHeight()
    ) {
      this.setState({
        dropdownHeight: this.calculateDropdownHeight(),
        dropdownStyle: this.calculateDropdownStyle(),
      });
    }

    if (prevProps.MaKhuVuc !== this.props.MaKhuVuc) {
      if (this.props.MaKhuVuc) {
        this.setState({ MaKhuVuc: this.props.MaKhuVuc });
        stationApi
          .getStationsByMaKhuVuc(this.props.MaKhuVuc)
          .then((data) => {
            console.log("get Station by MaKhuVuc", data);

            if (data && data.data.MoTa == "ThanhCong") {
              this.setState({
                radioStationLists: data.data.NoiDung?.DanhSachDaiTruyenThanh,
              });
              if (data.NoiDung?.DanhSachDaiTruyenThanh.length == 1) {
                this.props.onSelect(data.NoiDung?.DanhSachDaiTruyenThanh[0]);
              } else {
                if (data?.NoiDung?.DanhSachDaiTruyenThanh) {
                  for (let station of data?.NoiDung?.DanhSachDaiTruyenThanh) {
                    if (this.props.deviceStationId == station.id) {
                      this.props.onSelect(station);
                    }
                  }
                }
              }
            }
          })
          .catch();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.handleResize);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        dropdownHeight: 0,
      });
    }
  }

  handleResize(event) {
    this.setState({
      dropdownStyle: this.calculateDropdownStyle(),
    });
  }

  toggleDropdown() {
    if (this.props.isDisabled) {
      return;
    }
    if (!this.state.dropdownHeight) {
      this.setState({
        dropdownHeight: this.calculateDropdownHeight(), // + padding
        dropdownStyle: this.calculateDropdownStyle(),
      });
      let input = this.wrapperRef.current.querySelector(`input`);
      if (input) {
        input.focus();
      }
    } else {
      this.setState({
        dropdownHeight: 0,
      });
    }
  }

  filterRadioStation(e) {
    if (this.inputDebounceTimeout) {
      window.clearTimeout(this.inputDebounceTimeout);
    }
    this.inputDebounceTimeout = setTimeout(() => {
      this.setState({
        searchString: e.target.value,
      });
    }, 500);
  }

  onSelectRadioStation(radioStationId) {
    for (let item of this.state.radioStationLists) {
      if (item.id == radioStationId) {
        if (this.props.onSelect) {
          this.props.onSelect(item);
          break;
        }
      }
    }
    this.setState({
      dropdownHeight: 0,
    });
  }

  setDropdownHeight() {
    this.setState({
      dropdownStyle: this.calculateDropdownHeight(),
      dropdownStyle: this.calculateDropdownStyle(),
    });
  }

  calculateDropdownHeight() {
    return this.divRef.current?.scrollHeight + 12;
  }

  calculateDropdownStyle() {
    if (this.wrapperRef.current) {
      let dropdownWantedWidth = 500;
      let selectContainer = this.wrapperRef.current;
      let leftOffset = $(selectContainer).offset().left,
        widthOffset = selectContainer.offsetWidth,
        rightOffset = $(window).width() - leftOffset - widthOffset;

      let left = null,
        right = null,
        width = dropdownWantedWidth;
      if (rightOffset + widthOffset > dropdownWantedWidth) {
        // dropdown trái -> phải
        left = 0;
      } else if (leftOffset + widthOffset > dropdownWantedWidth) {
        // dropdown phải -> trái
        right = 0;
      } else {
        // dropdown căn theo phía nhiều khoảng trống hơn
        width = rightOffset + widthOffset - 15;

        if (rightOffset > leftOffset) {
          left = 0;
        } else {
          right = 0;
        }
      }
      if (
        (Math.abs(width - widthOffset) * 100) / ((width + widthOffset) / 2) <
          10 ||
        width < widthOffset
      ) {
        width = "100%";
      }
      return {
        left,
        right,
        width,
      };
    }
  }
  dataFilter(queryString) {
    if (queryString) {
      const data = this.state.radioStationLists.filter((item) => {
        return normalizeString(item?.Ten).includes(
          normalizeString(queryString)
        );
      });

      return data;
    } else {
      return this.state.radioStationLists;
    }
  }

  render() {
    let stationData = this.dataFilter(this.state.searchString);
    return (
      <div
        className={
          classes.container +
          " " +
          this.props.className +
          (this.props.isDisabled ? " q-disabled" : "")
        }
        style={{ width: "100%" }}
        ref={this.wrapperRef}
      >
        <div
          className={`${classes.placeholder_container} ${
            this.state.dropdownHeight
              ? "border-primary"
              : this.props.noBorder
              ? "border-0"
              : ""
          }`}
          onClick={this.toggleDropdown}
        >
          <div
            className={`${
              this.props.selectedRadioStation?.Ten ? "" : "opacity-40"
            } d-flex align-middle mw-100 pr-2`}
          >
            <span
              className="col px-0 text-truncate"
              style={{
                color: this.props.selectedRadioStation?.Ten ? null : "#B5B5C3",
              }}
            >
              {this.props.selectedRadioStation?.Ten || "Chọn đài truyền thanh"}
            </span>
            {this.props.selectedRadioStation && this.props.clearable ? (
              <i
                className="far fa-times ml-2"
                onClick={(e) => {
                  if (this.props.onSelect) {
                    this.props.onSelect(null);
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              ""
            )}
          </div>
          <i className="far fa-angle-down" />
        </div>
        <div
          className={`${classes.dropdown_container} ${
            this.state.dropdownHeight ? "" : "p-0 border-0"
          } ${this.props.dropdownClassName}`}
          style={{
            maxHeight: this.state.dropdownHeight,
            ...this.state.dropdownStyle,
          }}
          ref={this.divRef}
        >
          <input className="form-control" onInput={this.filterRadioStation} />
          <div className={classes.select_tree_container}>
            {stationData
              ? Array.isArray(stationData)
                ? stationData.map((station) => {
                    return this.props.exceptIds.includes(station.id) ? (
                      ""
                    ) : (
                      <SelectRadioStationItem
                        station={station}
                        key={station.id}
                        onSelect={this.onSelectRadioStation}
                        exceptIds={this.props.exceptIds}
                        setDropdownHeight={this.setDropdownHeight}
                      />
                    );
                  })
                : ""
              : ""}
          </div>
        </div>
      </div>
    );
  }
}

SelectRadioStation.propTypes = {
  onSelect: PropTypes.func.isRequired, // sự kiện khi chọn khu vực (tham số: khu vực được chọn (object))
  selectedRadioStation: PropTypes.object, // khu vực được chọn truyền từ component cha (mặc định null), cập nhật giá trị trong sự kiện onSelect trong component cha
  dropdownClassName: PropTypes.string,
  exceptIds: PropTypes.array,
  MaKhuVuc: PropTypes.string,
  noBorder: PropTypes.bool,
};

SelectRadioStation.defaultProps = {
  exceptIds: [],
  MaKhuVuc: "",
  noBorder: true,
  selectedRadioStation: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRadioStation);
