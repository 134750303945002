import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Global from "general/Global";
import useSort from "hooks/useSort";
import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import Connection from "components/Connection";
import State from "components/State";
import AppPagination from "components/AppPagination";
import { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import { thunkGetStations } from "pages/RadioStationManager/stationSlice";
import { useHistory } from "react-router-dom";
import ModalDelete from "components/AppModal/ModalDelete";
import deviceApi from "api/deviceApi";
import ToastHelper from "general/helpers/ToastHelper";
import usePrevious from "hooks/usePrevious";
import _ from "lodash";
import { AppIcons } from "general/constants/AppResource";
import bulletinBoardApi from "api/bulletinBoardApi";
import Utils, {
  findRadioStationsByMaDaiTruyenThanh,
  getNameAreasByMaKhuVuc,
} from "general/utils/Utils";

/**
 * Props:
 * * getDevicesApi (`func`): hàm nhận query-string từ bên trong để gọi api
 * * searchText (`string`): string to search on
 * 
 * Example:
 * ```js
 * import stationApi from '...';
 * 
 * <DevicesTable
    getDevicesApi={(params) => stationApi.getStationDevices(station.id, params)}
    searchText={searchText}
  />
 * ``` 
 */

let deleteName = "";
let textDelete = "";
let textStop = "";
let selectedDevice = {};
let selectedDevices = [];
let interval;
// const DEFAULT_ORDER = { field: "connection", mode: "DESC" };
const DEFAULT_ORDER = { field: "id", mode: "DESC" };

const DevicesTable = forwardRef((props, ref) => {
  DevicesTable.propTypes = {
    deviceType: PropTypes.string,
  };

  DevicesTable.defaultProps = {
    deviceType: "speaker",
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    getDevicesApi,
    searchText,
    currentAreaId,
    tableName,

    setNumberOfSelectedDevices,
    configured,
    tab,
    deviceType,
    reload,
  } = props;
  const history = useHistory();
  const areas = useSelector((state) => state.area.allAreas);
  const [isLoading, setIsLoading] = useState(true);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const [modalStopShowing, setModalStopShowing] = useState(false);
  const [deviceTotal, setDeviceTotal] = useState(0);
  const [devices, setDevices] = useState([]);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);
  const [checked, setChecked] = useState(true);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const tmpAreaId = currentAreaId;
  const prevValue = usePrevious({
    currentAreaId: currentAreaId,
    tableName: tableName,
    step: step,
    searchText: searchText,

    page: page,
    order: order,
  });

  const { directionIndicator, sortOnClick } = useSort(
    devices,
    () => {},
    (order) => setOrder(order)
  );

  useImperativeHandle(ref, () => ({
    handleDeleteMultipleDevices() {
      selectedDevices = devices[page - 1].filter((item) => {
        return item.checked == true;
      });

      if (selectedDevices && selectedDevices.length > 0) {
        textDelete = `Bạn chắc chắn muốn xoá ${selectedDevices?.length} thiết bị đã chọn?`;
        setModalDeleteShowing(true);
      }
    },

    handleConfigureMultipleDevices() {
      let deviceIds = [];
      if (selectedDevices && selectedDevices.length > 0) {
        deviceIds = selectedDevices.map((item) => {
          return item.deviceId;
        });
      } else {
        deviceIds = [selectedDevice.deviceId];
      }
      let id = "";
      for (let i = 0; i < deviceIds.length; i++) {
        if (i == deviceIds.length - 1) id += deviceIds[i];
        else id += deviceIds[i] + ".";
      }
      history.push("/device-manager/" + deviceType + "/configure/" + id);
    },
  }));
  useEffect(() => {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    console.log("[DeviceTable] refresh Device......", currentAreaId);
    if (currentAreaId && currentAreaId > 0) {
      interval = setInterval(() => {
        try {
          console.log("[DeviceTable] refresh Device......", currentAreaId);
          getDevicesApi({
            page: page - 1,
            limit: step,
            q: searchText,
            // orderField: order.field,
            orderField: "createdAt",
            orderMode: order.mode,
            MaKhuVuc: currentAreaId,
          }).then((res) => {
            let newDevices = [...devices];
            if (deviceType == "bulletin-board") {
              newDevices[page - 1] = res.data?.NoiDung?.danhSachBangTin;
            } else {
              newDevices[page - 1] = res.data?.NoiDung?.DanhSachCumLoa;
            }
            setDevices(newDevices);
            setDeviceTotal(res.data?.NoiDung?.count);
          });
        } catch (error) {
          console.log("[DeviceTable] refresh device", error);
        }
      }, 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [currentAreaId, searchText, step, order, page, getDevicesApi]);
  // MARK: --- Functions ---
  async function getDevices(resetDevice = false, page = 0) {
    setIsLoading(true);
    const res = await getDevicesApi({
      page: page,
      limit: step,
      q: searchText,
      orderField: "createdAt",
      orderMode: order.mode,
      MaKhuVuc: currentAreaId,
    });

    if (res && res.data.MoTa == "ThanhCong") {
      // debugger
      if (resetDevice) {
        let newDevices = [];
        for (let i = 0; i < Math.ceil(res.data?.NoiDung?.count / step) + 1; i++)
          newDevices[i] = [];
        if (deviceType == "bulletin-board") {
          newDevices[page] = res.data.NoiDung?.danhSachBangTin;
        } else {
          newDevices[page] = res.data?.NoiDung?.DanhSachCumLoa;
        }
        setDevices(newDevices);
      } else {
        let newDevices = [...devices];
        if (deviceType == "bulletin-board") {
          newDevices[page] = res.data.NoiDung?.danhSachBangTin;
        } else {
          newDevices[page] = res.data?.NoiDung?.DanhSachCumLoa;
        }
        setDevices(newDevices);
      }
      setDeviceTotal(res.data?.NoiDung?.count);
    } else {
      if (resetDevice) {
        setDevices([]);
        setDeviceTotal(0);
      }
    }
    setIsLoading(false);
  }

  function handleSetting(deviceId) {
    deviceType == "bulletin-board"
      ? history.push("/device-manager/bulletin-board/" + deviceId)
      : history.push("/device-manager/speaker/" + deviceId);
  }

  function handleDeleteOneDevice(deviceId) {
    textDelete = `Bạn chắc chắn muốn xoá thiết bị: ${deviceId}?`;
    setModalDeleteShowing(true);
  }

  function handleStop(deviceId) {
    selectedDevices = devices[page - 1].filter((item) => {
      return item.checked == true;
    });
    if (selectedDevices && selectedDevices.length > 0) {
      textStop = `Bạn chắc chắn muốn dừng ${selectedDevices.length} thiết bị đã chọn?`;
    } else {
      textStop = `Bạn chắc chắn muốn dừng thiết bị: ${selectedDevice.deviceId}?`;
    }
    setModalStopShowing(true);
  }

  function handleCheckBoxChanged() {
    selectedDevices = devices[page - 1]?.filter((item) => {
      return item.checked == true;
    });
    if (selectedDevices && selectedDevices.length > 0) {
      if (selectedDevices.length == devices[page - 1].length) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setNumberOfSelectedDevices(selectedDevices?.length);
    } else {
      setNumberOfSelectedDevices(null);
    }
  }
  console.log("[DviceTable] currentAreaId:", currentAreaId);
  // MARK: --- Hooks ---
  useEffect(() => {
    if (areas.length == 0) {
      dispatch(thunkGetAreas());
    }
    dispatch(thunkGetStations());
    return () => {
      setNumberOfSelectedDevices(null);
    };
  }, []);

  useEffect(() => {
    setChecked(false);
  }, [tab]);

  useEffect(() => {
    handleCheckBoxChanged();
  }, [devices[page - 1], step, page]);

  useEffect(() => {
    setPage(1);
    getDevices(true, 0);
  }, [searchText, currentAreaId, tableName, reload]);

  useEffect(() => {
    if (order) getDevices(true, page - 1);
  }, [order]);

  useEffect(() => {
    if (!Array.isArray(devices[page - 1]) || devices[page - 1]?.length === 0) {
      if (prevValue?.step === step) getDevices(false, page - 1);
      else getDevices(true, page - 1);
    } else {
      if (prevValue?.step !== step) getDevices(true, page - 1);
    }
  }, [page, step]);

  console.log(devices);

  const renderConnectionType = (type) => {
    switch (type?.toLowerCase()) {
      case "giantiep":
        return (
          <div
            className="d-flex status-box justify-content-center"
            style={{ background: "#C9F7F5" }}
          >
            {/* <img id={id} src={AppIcons.icOffline} className="pr-3" /> */}
            <p
              className="Regular_13 text-emphasis "
              style={{ color: "#1BC5BD " }}
            >
              Gián tiếp
            </p>
          </div>
        );
        break;
      case "tructiep":
        return (
          <div
            className="d-flex status-box justify-content-center"
            style={{ background: "#EEE5FF" }}
          >
            {/* <img id={id} src={AppIcons.icOffline} className="pr-3" /> */}
            <p
              className="Regular_13 text-emphasis "
              style={{ color: "#8950FC   " }}
            >
              Trực tiếp
            </p>
          </div>
        );
        break;

      default:
        return "";
        break;
    }
  };

  return (
    <>
      <DataTable
        cols={[0, 0, 30, 0, 0, 0, 0, 40, 30, 0]}
        headItems={[
          // Name
          <CheckBox
            checked={checked}
            onCheck={() => {
              if (devices[page - 1]) {
                let newDevices = [...devices];
                newDevices[page - 1] = devices[page - 1].map((item) => {
                  return { ...item, checked: true };
                });
                setDevices(newDevices);
                handleCheckBoxChanged();
                setChecked(true);
              }
            }}
            onUncheck={() => {
              if (devices[page - 1]) {
                let newDevices = [...devices];
                newDevices[page - 1] = devices[page - 1].map((item) => {
                  return { ...item, checked: false };
                });
                setDevices(newDevices);
                handleCheckBoxChanged();
                setChecked(false);
              }
            }}
          />,
          // Devices ID
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("deviceId")}
          >
            <p>
              ID
              {directionIndicator("deviceId")}
            </p>
          </div>,

          // Name
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("name")}
          >
            <p>
              {t("DeviceName")}
              {directionIndicator("name")}
            </p>
          </div>,

          // Connection
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("connection")}
          >
            <p>
              {t("Connect")}
              {directionIndicator("connection")}
            </p>
          </div>,

          // Provider
          t("Provider"),

          // Volume
          deviceType == "bulletin-board" ? null : (
            <div
              className="sort-direction w-100 cursor-pointer"
              onClick={() => sortOnClick("volume")}
            >
              <p>
                {t("Volume")}
                {directionIndicator("volume")}
              </p>
            </div>
          ),
          // State
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("state")}
          >
            <p>
              {t("State")}
              {directionIndicator("state")}
            </p>
          </div>,
          // State connection
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TrangThaiKetNoi")}
          >
            <p>
              {t("ConnectionState")}
              {directionIndicator("TrangThaiKetNoi")}
            </p>
          </div>,

          // Area
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("area")}
          >
            <p style={{ minWidth: 80 }}>
              {t("Area")}
              {directionIndicator("area")}
            </p>
          </div>,
          deviceType == "bulletin-board" ? null : (
            <div
              className="sort-direction w-100 cursor-pointer"
              onClick={() => sortOnClick("radioStation")}
            >
              <p>
                {t("RadioStation")}
                {directionIndicator("radioStation")}
              </p>
            </div>
          ),

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("state")}
          >
            <p>{t("Manage")}</p>
          </div>,
        ]}
        // row
        dataItems={
          devices[page - 1]
            ? devices[page - 1].map((item) => {
                return {
                  isRowCheck: item.checked,
                  rowClick: () => {},
                  data: [
                    {
                      comp: (
                        <CheckBox
                          checked={item.checked}
                          onCheck={() => {
                            item.checked = true;
                            handleCheckBoxChanged();
                          }}
                          onUncheck={() => {
                            item.checked = false;
                            handleCheckBoxChanged();
                          }}
                        />
                      ),
                    },
                    {
                      comp: (
                        <div
                          key={Math.random()}
                          className="d-flex flex-row align-items-center custom-row-checkbox cursor-pointer position-relative"
                          style={
                            deviceType == "bulletin-board"
                              ? { width: 160 }
                              : null
                          }
                        >
                          {deviceType == "bulletin-board" ? (
                            <img
                              src={AppIcons.icBulletinBoard}
                              className="pr-2"
                              style={{ width: "23px", height: "18px" }}
                            />
                          ) : (
                            <i className="fas fa-satellite-dish pr-2 text-primary mb-1"></i>
                          )}
                          <p
                            className="Bold_13 text-primary text-emphasis overflow-wrap"
                            style={{
                              left: 20,
                              width: 180,
                            }}
                          >
                            {deviceType == "bulletin-board"
                              ? item?.BangTinDienTuID
                              : item?.CumLoaID}
                          </p>
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    {
                      comp: (
                        <div
                          className="position-relative"
                          onClick={() => {
                            handleSetting(
                              deviceType == "bulletin-board"
                                ? item?.BangTinDienTuID
                                : item?.CumLoaID
                            );
                          }}
                          style={
                            deviceType == "bulletin-board"
                              ? { width: 170 }
                              : null
                          }
                        >
                          <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                            {item?.TenThietBi}
                          </p>
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    {
                      comp: (
                        <div
                          onClick={() => {
                            handleSetting(
                              deviceType == "bulletin-board"
                                ? item?.BangTinDienTuID
                                : item?.CumLoaID
                            );
                          }}
                          style={
                            deviceType == "bulletin-board"
                              ? { width: 110 }
                              : null
                          }
                        >
                          {renderConnectionType(item?.KieuKetNoi)}
                          {/* <Connection type={item?.TrangThaiKetNoi} /> */}
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    {
                      comp: (
                        <div>
                          <p
                            className="text-center Regular_13 text-emphasis mt-1"
                            onClick={() => {
                              handleSetting(
                                deviceType == "bulletin-board"
                                  ? item?.BangTinDienTuID
                                  : item?.CumLoaID
                              );
                            }}
                          >
                            {/* {item["providerAccount.provider.name"]
                              ? item["providerAccount.provider.name"]
                              : item.providerAccount
                              ? item.providerAccount.provider.name
                              : ""} */}
                            {item?.TenNhaCungCap}
                          </p>
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    deviceType == "bulletin-board" ? (
                      <></>
                    ) : (
                      {
                        comp: (
                          <p
                            className="text-center Regular_13 text-emphasis mt-1"
                            onClick={() => {
                              handleSetting(
                                deviceType == "bulletin-board"
                                  ? item?.BangTinDienTuID
                                  : item?.CumLoaID
                              );
                            }}
                          >
                            {item?.AmLuong && item?.AmLuong + "%"}
                          </p>
                        ),
                        onClick: () => {
                          handleSetting(
                            deviceType == "bulletin-board"
                              ? item?.BangTinDienTuID
                              : item?.CumLoaID
                          );
                        },
                      }
                    ),
                    {
                      comp: (
                        <div
                          className="position-relative"
                          style={
                            deviceType == "bulletin-board"
                              ? { width: 140 }
                              : { width: 90 }
                          }
                          onClick={() => {
                            handleSetting(
                              deviceType == "bulletin-board"
                                ? item?.BangTinDienTuID
                                : item?.CumLoaID
                            );
                          }}
                        >
                          {item?.TrangThaiHoatDong && (
                            <div className="position-absolute w-100 top-50 translate-middle-y">
                              <State state={item?.TrangThaiHoatDong} />
                            </div>
                          )}
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    {
                      comp: (
                        <div
                          className="position-relative"
                          style={
                            deviceType == "bulletin-board"
                              ? { width: 140 }
                              : { width: 90 }
                          }
                          onClick={() => {
                            handleSetting(
                              deviceType == "bulletin-board"
                                ? item?.BangTinDienTuID
                                : item?.CumLoaID
                            );
                          }}
                        >
                          {item?.TrangThaiKetNoi && (
                            <div className="position-absolute w-100 top-50 translate-middle-y">
                              <Connection
                                type={
                                  !item?.TrangThaiKetNoi ||
                                  item?.TrangThaiKetNoi == "Mất kết nối"
                                    ? "OFFLINE"
                                    : item?.TrangThaiKetNoi
                                }
                                text={item?.TrangThaiKetNoi}
                              />
                            </div>
                          )}
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    {
                      comp: (
                        <div
                          className="position-relative"
                          onClick={() => {
                            handleSetting(
                              deviceType == "bulletin-board"
                                ? item?.BangTinDienTuID
                                : item?.CumLoaID
                            );
                          }}
                          style={
                            deviceType == "bulletin-board"
                              ? { minWidth: 120 }
                              : null
                          }
                        >
                          <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                            {/* {item["area.name"]
                              ? item["area.name"]
                              : item.area
                              ? item.area.name
                              : ""} */}

                            {getNameAreasByMaKhuVuc(item?.MaKhuVuc)?.Ten}
                          </p>
                        </div>
                      ),
                      onClick: () => {
                        handleSetting(
                          deviceType == "bulletin-board"
                            ? item?.BangTinDienTuID
                            : item?.CumLoaID
                        );
                      },
                    },
                    deviceType == "bulletin-board" ? (
                      <></>
                    ) : (
                      {
                        comp: (
                          <div
                            className="position-relative"
                            onClick={() => {
                              handleSetting(
                                deviceType == "bulletin-board"
                                  ? item?.BangTinDienTuID
                                  : item?.CumLoaID
                              );
                            }}
                          >
                            <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                              {
                                findRadioStationsByMaDaiTruyenThanh(
                                  item?.MaDaiTruyenThanh
                                )?.Ten
                              }
                            </p>
                          </div>
                        ),
                        onClick: () => {
                          handleSetting(
                            deviceType == "bulletin-board"
                              ? item?.BangTinDienTuID
                              : item?.CumLoaID
                          );
                        },
                      }
                    ),
                    {
                      comp: (
                        <div
                          className="d-flex justify-content-center"
                          style={{ width: 80 }}
                        >
                          <i
                            className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                            onClick={() => {
                              handleSetting(
                                deviceType == "bulletin-board"
                                  ? item?.BangTinDienTuID
                                  : item?.CumLoaID
                              );
                            }}
                          ></i>
                          {configured ? (
                            <i
                              className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                              onClick={() => {
                                deleteName = item.name + " - " + item?.CumLoaID;
                                selectedDevice = item;
                                handleDeleteOneDevice(item?.CumLoaID);
                              }}
                            ></i>
                          ) : null}

                          {configured ? (
                            item?.TrangThaiHoatDong === 0 ? (
                              <i
                                className="fas fa-stop-circle cursor-pointer text-danger text-hover-primary"
                                onClick={() => {
                                  deleteName =
                                    item.name + " - " + item.deviceId;
                                  selectedDevice = item;
                                  handleStop(item);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fas fa-play-circle"
                                onClick={() => {}}
                              ></i>
                            )
                          ) : null}
                        </div>
                      ),
                    },
                  ],
                };
              })
            : []
        }
        step={step}
        onRefresh={() => getDevices(true, page - 1)}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white rounded-bottom"
        total={deviceTotal}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
      <ModalDelete
        show={modalDeleteShowing}
        onClose={() => setModalDeleteShowing(false)}
        onSubmit={() => {
          let deviceIds = [];
          if (selectedDevices && selectedDevices.length > 0) {
            deviceIds = selectedDevices.map((item) => {
              return item.deviceId;
            });
          } else {
            deviceIds = [selectedDevice.deviceId];
            // deviceIds = [selectedDevice.id];
          }
          if (deviceType == "bulletin-board") {
            bulletinBoardApi
              .deleteBulletinBoard({ deviceIds: deviceIds })
              .then((data) => {
                if (data.data.status == 0) {
                  ToastHelper.showSuccess("Xoá thiết bị thành công");
                  getDevices(true, page - 1);
                }
              });
          } else {
            deviceApi.delete({ deviceIds: deviceIds }).then((data) => {
              if (data.data.status == 0) {
                ToastHelper.showSuccess("Xoá thiết bị thành công");
                getDevices(true, page - 1);
              }
            });
          }
        }}
        title={t("DeleteDevice")}
        text={textDelete}
      />

      <ModalDelete
        show={modalStopShowing}
        onClose={() => setModalStopShowing(false)}
        onSubmit={() => {
          let deviceIds = [];
          if (selectedDevices && selectedDevices.length > 0) {
            deviceIds = selectedDevices.map((item) => {
              return item.deviceId;
            });
          } else {
            deviceIds = [selectedDevice.deviceId];
          }

          deviceApi.stop({ deviceIds: deviceIds }).then((data) => {
            if (data.data.status == 0) {
              if (deviceIds.length > 1) {
                ToastHelper.showSuccess(`Đã dừng ${deviceIds.length} thiết bị`);
              } else if (deviceIds.length == 1) {
                ToastHelper.showSuccess(`Đã dừng thiết bị ${deviceIds[0]}`);
              }

              getDevices(true, page - 1);
            }
          });
        }}
        title={t("StopDevice")}
        text={textStop}
      />
    </>
  );
});

export default DevicesTable;
