import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getAllSupportedMimeTypes } from "../../../../general/utils/Utils";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import Visualizer from "../Visualizer";
import _ from "lodash";
import useWebSocket from "react-use-websocket";
const MicRecorder = require("mic-recorder-to-mp3");
const PrimaryButton = styled.button`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;
const PLAYING_STATE = {
  START: "START",
  STOP: "STOP",
  PAUSE: "PAUSE",
};
const AUDIO_SUPPORTED_MIME_TYPES = getAllSupportedMimeTypes();

export default function PlayDirect({
  show,
  playDirect,
  micID,
  onClose,
  banTinId,
}) {
  let audioStream = useRef(null);
  const micRecoder = useRef(null);
  const intervalId = useRef(null);
  const [playingState, setPlayingState] = useState(PLAYING_STATE.STOP);

  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    // `wss://htttn.ipfm.vn/ws_v2?wsId=${12345}&banTinID=${banTinId}&type=RTMP`,
    "ws://localhost:3000",
    {
      onMessage: (message) => {
        console.log(message);
      },
      filter: () => false,
      shouldReconnect: (_closeEvent) => true, //what does the close event look like when caused by the 2 hour limit? Is there a different error code that you could use? I would look into returning false in this case and adding the following option:
      retryOnError: true, //With this true, and returning false in the 'shouldReconnect' will avoid trying to reconnect twice for the same event
      onClose: () => console.log("WEBSOCKET closing"),
      onError: (err) => console.log("WEBSOCKET error", err),
      onOpen: () => console.log("WEBSOCKET open"),
    }
  );

  const getUserPermission = () => {
    if (!AUDIO_SUPPORTED_MIME_TYPES.length) {
      return ToastHelper.showError("Trình duyệt không hỗ trợ ghi âm");
    }
    let constraints = { audio: true, video: false };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        audioStream.current = stream;
      })
      .catch(function (err) {
        console.error(err);
        ToastHelper.showError(
          "Không thể ghi âm, vui lòng cấp quyền truy cập thiết bị"
        );
      });
  };

  useEffect(() => {
    getUserPermission();
  }, [show]);

  const onPlayDirect = (command = "START_RTMP") => {
    console.log("LOG command: " + command);
    if (micID) {
      let config = {
        bitRate: 128,
        deviceId: micID,
      };

      if (!micRecoder.current) {
        micRecoder.current = new MicRecorder(config);
      }

      micRecoder.current
        .start()
        .then(() => {
          sendMessage(JSON.stringify({ command: command, BanTinId: banTinId }));
          const id = setInterval(() => {
            micRecoder.current.getMp3().then(([buffer, blob]) => {
              for (let i = 0; i < buffer.length; i++) {
                let arrayBuffer = new Uint8Array(buffer[i]);
                let sendData = new Uint8Array(arrayBuffer.length);
                sendData.set(arrayBuffer);
                sendMessage(sendData);
              }
            });
          }, 1000);
          intervalId.current = id;
        })
        .catch((exception) => {
          console.error(exception);
          if (exception instanceof OverconstrainedError) {
            toast({
              icon: "error",
              title: "Truy cập mic lỗi.",
            });
          }
        });
    }
  };

  const onStartPlaying = () => {
    setPlayingState(PLAYING_STATE.START);
    if (playDirect) {
      onPlayDirect();
    }
  };

  const onResumePlaying = () => {
    setPlayingState(PLAYING_STATE.START);
    if (playDirect) {
      onPlayDirect("RESUME_RTMP");
    }
  };

  const onStopPlaying = () => {
    setPlayingState(PLAYING_STATE.STOP);
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    if (micRecoder.current) {
      micRecoder.current.stop();
    }
    sendMessage(JSON.stringify({ command: "STOP_RTMP", BanTinId: banTinId }));
  };

  const onPausePlaying = () => {
    setPlayingState(PLAYING_STATE.PAUSE);
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    if (micRecoder.current) {
      micRecoder.current.stop();
    }
    sendMessage(JSON.stringify({ command: "PAUSE_RTMP", BanTinId: banTinId }));
  };

  // toggle pause/resume
  const onTogglePlayingState = (pause = true) => {
    if (pause) {
      onPausePlaying();
    } else {
      onResumePlaying();
    }
  };

  const handleCloseModal = () => {
    onClose(false);
    if (audioStream.current) {
      audioStream.current.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <Modal
      centered={true}
      isOpen={show}
      //   toggle={handleCloseModal}
      backdrop={true}
    >
      <ModalBody>
        <Visualizer
          mediaStream={audioStream.current}
          width={400}
          height={400}
        />
        <div className="Regular_13 text-center">
          Bạn hãy nói vào thiết bị để thử âm thanh
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex m-0 w-100">
          {playingState === PLAYING_STATE.STOP && (
            <>
              <PrimaryButton
                className={"col px-0 mr-3"}
                onClick={onStartPlaying}
              >
                <i className="fas fa-play-circle" />
                Bắt Đầu
              </PrimaryButton>
              <div style={{ paddingRight: 8, paddingLeft: 0 }} className="col">
                <button
                  className="btn btn-light border w-100"
                  onClick={handleCloseModal}
                >
                  <i className="fas fa-stop" />
                  Đóng lại
                </button>
              </div>
            </>
          )}

          {playingState === PLAYING_STATE.START && (
            <div style={{ paddingRight: 8, paddingLeft: 0 }} className="col">
              <button
                className="btn btn-white border w-100"
                onClick={() => onTogglePlayingState(true)}
              >
                <i className="fas fa-pause-circle" />
                Tạm Dừng
              </button>
            </div>
          )}

          {playingState === PLAYING_STATE.PAUSE && (
            <div style={{ paddingRight: 8, paddingLeft: 0 }} className="col">
              <button
                className="Bold_13 btn btn-success w-100"
                onClick={() => onTogglePlayingState(false)}
              >
                <i className="fas fa-play-circle" />
                Tiếp Tục
              </button>
            </div>
          )}

          {playingState !== PLAYING_STATE.STOP && (
            <div style={{ paddingLeft: 8, paddingRight: 0 }} className="col">
              <button
                className="Bold_13 btn btn-danger w-100"
                onClick={onStopPlaying}
              >
                <i className="fas fa-stop-circle" />
                Kết Thúc
              </button>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}
