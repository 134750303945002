import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";
import Utils, {
  buildURL,
  findAreaByMaKhuVuc,
  formatArea,
  formatDuration,
  formatFileObj,
  getFileDuration,
  useQuery,
} from "general/utils/Utils";
import styled from "styled-components";
import AppPage from "components/AppPage";
import AppCard from "components/AppCard";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import BaseTextField from "../../../../general/custom-fields/BaseTextField";
import ChooseSourceTypeButton from "../../components/ChooseSourceTypeButton";
import {
  BULLETIN_CATEGORY,
  BULLETIN_SOURCE_TYPES,
  BULLETIN_TYPE,
} from "../../../../general/constants/Types";
import CurvedTab from "../../components/CurvedTab";
import moment from "moment";
import _ from "lodash";
import ChooseSource from "../../components/ChooseSource";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import DevicesPicker from "../../components/DevicesPicker";
import { bulletinGetConfig } from "../../bulletinSlice";
import bulletinApi from "../../../../api/bulletinApi";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../../components/Loading";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import PreviewAudio from "../../components/PreviewAudio";
import { SelectSort } from "../../../Category/components/SelectSort";
import newsApi from "api/newsApi";
import PlayDirect from "pages/BulletinManager/components/PlayDirect/PlayDirect";
import { BulletinHelper } from "general/helpers/BulletinHelper";

const PrimaryButton = styled.button`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;

  &.btn-success {
    background: #3ace5a !important;
    border: 1px solid #2ab448 !important;
  }

  &.btn-danger {
    background: #e64135 !important;
    border: 1px solid #cd1f13 !important;
  }

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
  }
`;

const AttributeRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
    flex-direction: column;
    align-items: initial;
  }
`;

const AttributeTitle = styled.div`
  width: 140px;
  max-width: 40%;
  margin-right: 12px;
  text-align: right;

  @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
    width: initial;
    max-width: none;
    text-align: initial;
    margin-bottom: 5px;
  }
`;

const AttributeInput = styled.div`
  flex: 1;
`;

BulletinEmergencyCreateScreen.propTypes = {};

let playTimeDefault = () => ({
  startTime: moment().startOf("minute"),
  endTime: moment().startOf("minute"),
});

function BulletinEmergencyCreateScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current);
  const [loaiLinhVuc, setLoaiLinhVuc] = useState();
  // MARK: --- Params ---
  const categories = useSelector((state) => {
    if (!loaiLinhVuc && state.bulletin.categories.length) {
      setLoaiLinhVuc(state.bulletin.categories[0].id);
    }
    return _.map(state.bulletin.categories, function (category) {
      return {
        ...category,
        display: category.name,
      };
    });
  });
  const allArea = useSelector((state) => state.area.allAreas);
  const listArea = useSelector((state) => state.area.listArea);
  const canAcceptProgram = useSelector(
    (state) => !!state.auth?.current?.acceptProgram
  );
  const canEditProgram = useSelector(
    (state) => !!state.auth?.current?.editProgram
  );
  const routeParams = useParams();
  const history = useHistory();
  const query = useQuery();
  const [eBulletin, setEBulletin] = useState(
    props.location?.state?.eBulletinData
  );
  const [showLoading, setShowLoading] = useState(false);

  const disableAll = query.get("history_id");
  const [tieuDe, setTieuDe] = useState("");
  const [noiDungTomTat, setNoiDungTomTat] = useState("");
  const [sourceType, setSourceType] = useState(
    BULLETIN_SOURCE_TYPES.find((v) => v.active)?.type
  );
  // play sources
  // array of mp3 files
  const [files, setFiles] = useState([]);
  // array of record files
  const [recordFiles, setRecordFiles] = useState([]);
  const [playDirectStatus, setPlayDirectStatus] = useState(false);
  const [micID, setMicID] = useState("");

  //  AUX device id
  const [device, setDevice] = useState();
  // tts doc content
  const [document, setDocument] = useState();
  const [documentVoice, setDocumentVoice] = useState();
  const [ttsData, setTtsData] = useState();
  const [relaySource, setRelaySource] = useState({
    id: null,
    type: "",
    url: "",
    frequency: "",
  });

  const [activeTab, setActiveTab] = useState(0);
  const [playTime, setPlayTime] = useState([playTimeDefault()]);

  // khu vuc phat
  const [area, setArea] = useState();
  const [daiTruyenThanh, setDaiTruyenThanh] = useState();
  // loa phat
  const [speakers, setSpeakers] = useState([]);

  const [approveDate, setApproveDate] = useState();
  const [id, setID] = useState();
  const [creator, setCreator] = useState();
  const [loaiBanTin, setLoaiBanTin] = useState("");

  // url nghe trực tiếp / nghe lại
  const [session, setSession] = useState();
  const [sessionURL, setSessionURL] = useState();
  const [isPlayingSession, setIsPlayingSession] = useState(false);
  const [sessionURLPlaying, setSessionURLPlaying] = useState(false);
  const [playingDirect, setPlayingDirect] = useState(false);
  const [banTinID, setBanTinId] = useState("");

  // reject
  const [rejectReasonModalShow, setRejectReasonModalShow] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const listSourceButtonRef = useRef();

  // MARK: --- Functions ---
  const toggleRejectReasonModal = () => {
    setRejectReasonModalShow(!rejectReasonModalShow);
  };

  // create, update bulletin
  const createBulletin = async (autoApprove = true) => {
    setShowLoading(true);
    let bulletinData = {
      TieuDe: tieuDe,
      LoaiBanTin: loaiBanTin,
      NoiDungTomTat: noiDungTomTat,
      NoiDung: "",
      ThoiGianSanXuat: parseInt(Date.now() / 1000),
      MucDoUuTien: "1",
      LoaiLinhVuc: loaiLinhVuc,
      DiaBanTao: currentUser?.MaKhuVuc,
      TenDiaBanTao: allArea?.find(
        (area) => area?.MaKhuVuc == currentUser?.MaKhuVuc
      )?.Ten,
      TacGia: {
        TenDayDu: currentUser?.Ten || "",
        ButDanh: currentUser?.ButDanh || "",
        Email: currentUser?.Email || "",
      },
      NguonTin: currentUser?.MaKhuVuc,
      DanhSachDiaBanNhan: BulletinHelper.getDanhSachDiaBanNhanBanTinKhanCap(
        speakers,
        listArea
      ),
      TiepAm: sourceType == "RELAY" ? 0 : 1,
      NguonTiepAm: JSON.stringify(relaySource),
    };

    if (eBulletin) {
      // update
      bulletinData.id = eBulletin.id;
    } else {
      // create
      bulletinData.NoiDung = await BulletinHelper.getNoiDung(
        sourceType,
        playDirectStatus,
        files,
        recordFiles,
        ttsData
      );
      bulletinData.ThoiLuong = await BulletinHelper.getDuration(
        sourceType,
        files,
        recordFiles,
        ttsData
      );
    }
    console.log("LOG bulletin data", bulletinData, files);

    const resCreateBulletin = await newsApi.createIpRadioEmergencyBulletin(
      bulletinData
    );

    setShowLoading(false);
    if (resCreateBulletin?.data?.MoTa === "ThanhCong") {
      ToastHelper.showSuccess(
        routeParams.BanTinID
          ? "Cập nhật bản tin khẩn cấp thành công"
          : "Tạo bản tin khẩn cấp thành công"
      );
      setBanTinId(resCreateBulletin?.data?.NoiDung?.BanTinID);
      return resCreateBulletin?.data?.NoiDung?.BanTinID;
      // if (!routeParams.BanTinID) {
      //   history.push(
      //     `/bulletins/edit/speaker/${resCreateBulletin?.data?.NoiDung?.BanTinID}`,
      //     {
      //       eBulletinData: resCreateBulletin?.data?.NoiDung,
      //     }
      //   );
      // } else {
      //   setEBulletin(resCreateBulletin?.data?.NoiDung);
      // }
    }
    return "";
  };

  const playBulletin = async () => {
    const BanTinId = await createBulletin();
    if (BanTinId) {
      if (playDirectStatus && sourceType === "MIC") {
        console.log("LOG setting show");
        setPlayingDirect(true);
      }
    }
  };

  const approveBulletin = async (approve = true) => {
    if (!canAcceptProgram) return;
    let response;
    if (approve) {
      response = await bulletinApi.approve(eBulletin?.id, 1);
    } else {
      setRejectReasonModalShow(false);
      response = await bulletinApi.approve(eBulletin?.id, 2, rejectReason);
    }
    if (response.data.result === "success") {
      ToastHelper.showSuccess(
        `${approve ? "Phê duyệt" : "Từ chối"} bản tin thành công`
      );
      if (response.data.bulletin) {
        setEBulletin(response.data.bulletin);
      }
    }
  };

  // MARK: --- Hooks ---
  useEffect(() => {
    // assing rows when flexbox or grid  wraps into a new line
    const assignRows = (listButtonWrapper) => {
      let row = 0;
      // console.dir(cards.children);
      [...listButtonWrapper.children].forEach((el) => {
        // remove classes
        el.classList.remove("mt-3");
        // if element is more to the left that it's previous element it means it wrapped to a new line
        if (
          !el.previousElementSibling ||
          el.offsetLeft < el.previousElementSibling.offsetLeft
        ) {
          row++;
        }
        // adds a class of row1, row2, row3
        el.classList.add(`${row > 1 ? "mt-3" : "not-wrapped"}`);
      });
    };

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        assignRows(entry.target);
      });
    });

    const listButtonWrapper = listSourceButtonRef.current;
    observer.observe(listButtonWrapper);
    assignRows(listButtonWrapper);
  }, []);

  useEffect(() => {
    window.history.replaceState(null, "");
    dispatch(bulletinGetConfig());
  }, []);

  useEffect(async () => {
    // get bullet data
    let BanTinID = routeParams.BanTinID;
    if (!BanTinID || BanTinID == eBulletin?.id) {
      return;
    }
    setShowLoading(true);
    let bulletinResponse = await bulletinApi.getEBulletinData(BanTinID);
    setShowLoading(false);
    if (
      bulletinResponse?.data?.result === "success" &&
      bulletinResponse?.data?.bulletin
    ) {
      setEBulletin(bulletinResponse?.data?.bulletin);
    }
  }, [routeParams.BanTinID]);

  useEffect(async () => {
    // assign bulletin data to state,
    if (eBulletin) {
      let sourceType = "";
      if (eBulletin.type === "MP3") {
        sourceType = "FILE";
        if (Array.isArray(eBulletin.content.files)) {
          let serverFiles = [];
          for (const file of eBulletin.content.files) {
            serverFiles.push(await formatFileObj(file));
          }
          setFiles(serverFiles);
        }
      } else if (eBulletin.type === "MIC") {
        sourceType = "MIC";
        if (Array.isArray(eBulletin.content.recordFiles)) {
          let serverFiles = [];
          for (const file of eBulletin.content.recordFiles) {
            serverFiles.push(await formatFileObj(file));
          }
          setRecordFiles(serverFiles);
        }
      } else if (eBulletin.type === "AUX") {
        sourceType = "DEVICE";
        setDevice(eBulletin.content.link);
      } else if (eBulletin.type === "TTS") {
        sourceType = "DOCUMENT";
        if (eBulletin?.content?.ttsSource) {
          setTtsData(eBulletin.content.ttsSource);
          setDocument(eBulletin.content.ttsSource?.text);
          setDocumentVoice(eBulletin.content.ttsSource?.voiceId);
        }
      } else if (eBulletin.type === "DFM" || eBulletin.type === "AFM") {
        sourceType = "RELAY";
        setRelaySource(eBulletin.content.relaySource);
      }

      setTieuDe(eBulletin?.TieuDe);
      setNoiDungTomTat(eBulletin.description);
      if (sourceType) setSourceType(sourceType);

      if (
        Array.isArray(eBulletin.startTime) &&
        Array.isArray(eBulletin.endTime)
      ) {
        let playTimeArr = [];
        eBulletin.startTime.map(function (value, i) {
          let startTime = moment(`2022-01-01 ${value}`),
            endTime = moment(`2022-01-01 ${eBulletin.endTime?.[i]}`);
          playTimeArr.push({
            startTime,
            endTime,
          });
        });
        setPlayTime(playTimeArr);
      }
      setLoaiLinhVuc(eBulletin.LoaiLinhVuc);
      setArea(formatArea(eBulletin.area));
      setSpeakers(eBulletin.devices);
      setID(eBulletin.id);
      setCreator(eBulletin.creator);

      if (disableAll) {
        let bulletinListenResponse = await bulletinApi.getListenUrl([
          query.get("history_id"),
        ]);
        if (bulletinListenResponse?.data?.result === "success") {
          setSessionURL(bulletinListenResponse?.data?.data?.[0]?.link);
          setIsPlayingSession(
            !!bulletinListenResponse?.data?.data?.[0]?.status
          );

          let bulletinHistoryResponse = await bulletinApi.getHistory(
            query.get("history_id")
          );
          setSession(
            bulletinHistoryResponse?.data?.data?.bulletinHistory ||
              bulletinHistoryResponse?.data?.bulletinHistory
          );
        }
      } else {
        if (eBulletin.type === "MP3") {
          setSessionURL(Utils.getFullUrl(eBulletin?.content?.link));
        }
      }
    }
  }, [eBulletin]);

  useCustomCompareEffect(
    async () => {
      if (eBulletin?.id) return;
      let totalDuration = 0;
      if (sourceType === "FILE") {
        totalDuration = _.sumBy(files, "playDuration") || 0;
      } else if (sourceType === "MIC") {
        totalDuration = _.sumBy(recordFiles, "playDuration") || 0;
      } else if (sourceType === "DOCUMENT") {
        if (ttsData && ttsData.audioContent) {
          ({ duration: totalDuration } = await getFileDuration(
            buildURL(ttsData.audioContent)
          ));
        }
      }
      if (!totalDuration || totalDuration < 0) return;
      setPlayTime(
        [...playTime].map(function ({ startTime, endTime }) {
          return {
            startTime,
            endTime: startTime.clone().add(totalDuration, "seconds"),
          };
        })
      );
    },
    [sourceType, files, recordFiles, ttsData],
    (prevDeps, nextDeps) => _.isEqual(prevDeps, nextDeps)
  );

  useEffect(() => {
    if (!area && allArea.length && currentUser.MaKhuVuc) {
      let rootArea = findAreaByMaKhuVuc(currentUser.MaKhuVuc);
      if (rootArea) {
        setArea(rootArea);
      }
    }
  }, [allArea, currentUser.MaKhuVuc]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(255,255,255,0.5)",
          zIndex: 9999,
          display: showLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
      <AppPage
        backgroundImage={AppImages.imgBackground6}
        headerTitle={t("BulletinManager")}
      >
        <NavBar navBarTitle={t("BulletinManager")}></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={
              disableAll
                ? "Thông Tin Bản Tin Phát thanh khẩn cấp"
                : routeParams.BanTinID
                ? "Cập nhật bản tin phát thanh khẩn cấp"
                : "Thêm bản tin phát thanh khẩn cấp"
            }
            headerRight={
              !disableAll && (
                <div className="">
                  {
                    <PrimaryButton
                      className="btn btn-primary"
                      onClick={playBulletin}
                    >
                      <i className="far fa-upload mr-2" />
                      Phát bản tin
                    </PrimaryButton>
                  }
                  {routeParams.BanTinID && (
                    <PrimaryButton
                      className="btn btn-primary"
                      onClick={createBulletin}
                      style={{ marginLeft: 16 }}
                    >
                      <i className="far fa-pen mr-2" />
                      Cập nhật
                    </PrimaryButton>
                  )}
                  {!routeParams.BanTinID && (
                    <PrimaryButton
                      className="btn btn-success"
                      onClick={() => createBulletin(false)}
                      style={{ marginLeft: 16 }}
                    >
                      <i className="far fa-bookmark mr-2" />
                      Lưu Lại
                    </PrimaryButton>
                  )}
                  {canAcceptProgram &&
                    !!routeParams.BanTinID &&
                    eBulletin?.status === "WAITING" && (
                      <>
                        <PrimaryButton
                          className="btn btn-success"
                          onClick={() => approveBulletin(true)}
                          style={{ marginLeft: 16 }}
                        >
                          <i className="far fa-check mr-2" />
                          Phê Duyệt
                        </PrimaryButton>
                        <PrimaryButton
                          className="btn btn-danger"
                          onClick={toggleRejectReasonModal}
                          style={{ marginLeft: 16 }}
                        >
                          <i className="far fa-times mr-2" />
                          Từ Chối
                        </PrimaryButton>
                      </>
                    )}
                </div>
              )
            }
            buttonText={""}
          >
            <div style={{ background: "#F6F7FB", padding: 10, paddingTop: 0 }}>
              <div className="row">
                <div className="col-12 col-xl-8" style={{ marginTop: 10 }}>
                  <div
                    style={{
                      border: "1px solid #DBE3EF",
                      padding: "20px 10px 10px",
                      background: "white",
                    }}
                  >
                    <BaseTextField
                      className={"Regular_13 mb-3"}
                      name={"title"}
                      label={t("BulletinTitle")}
                      placeholder={t("InsertBulletinTitle")}
                      fieldProps={{
                        value: tieuDe,
                        onChange: (e) => setTieuDe(e.target.value),
                      }}
                      disabled={disableAll}
                    />
                    <BaseTextField
                      className={"Regular_13 mb-3"}
                      name={"title"}
                      label={t("BulletinSummary")}
                      placeholder={t("InsertBulletinSummary")}
                      multiLine={true}
                      fieldProps={{
                        rows: 4,
                        value: noiDungTomTat,
                        onChange: (e) => setNoiDungTomTat(e.target.value),
                      }}
                      disabled={disableAll}
                    />
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        background: "#E4E6EF",
                        margin: "12px 0 10px",
                      }}
                    ></div>
                    <div ref={listSourceButtonRef}>
                      <span className="Regular_13" style={{ marginRight: 12 }}>
                        {t("BulletinChooseSource")}
                      </span>
                      {BULLETIN_SOURCE_TYPES.map(({ type, title, icon }) => (
                        <ChooseSourceTypeButton
                          key={type}
                          title={
                            <span>
                              <i className={`far fa-${icon} mr-2`} />
                              {t(title)}
                            </span>
                          }
                          active={type === sourceType}
                          btnProps={{
                            onClick: () => {
                              setSourceType(type);
                            },
                            disabled: disableAll,
                          }}
                        />
                      ))}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        background: "#E4E6EF",
                        margin: "12px 0 10px",
                      }}
                    />
                    <ChooseSource
                      sourceType={sourceType}
                      disableAll={disableAll}
                      // source file
                      files={files}
                      onFilesChange={setFiles}
                      // source mic
                      recordFiles={recordFiles}
                      onRecordFilesChange={setRecordFiles}
                      playDirect={playDirectStatus}
                      onPlayDirectChange={(value) => {
                        console.log("LOG changed", value);
                        setPlayDirectStatus(value);
                      }}
                      micID={micID}
                      onChangeMicID={setMicID}
                      // source device
                      device={device}
                      onDeviceChange={setDevice}
                      // source document
                      document={document}
                      onDocumentChange={setDocument}
                      documentVoice={documentVoice}
                      onDocumentVoiceChange={setDocumentVoice}
                      ttsData={ttsData}
                      onTTSDataChange={setTtsData}
                      // source relay
                      relaySource={relaySource}
                      onRelaySourceChange={setRelaySource}
                    />
                  </div>

                  {sessionURL && (session || sourceType === "FILE") && (
                    <div
                      className="d-sm-flex justify-content-between align-items-center"
                      style={{
                        padding: 10,
                        border: "1px solid #DBE3EF",
                        borderTop: "none",
                      }}
                    >
                      <PrimaryButton
                        onClick={() => {
                          setSessionURLPlaying(true);
                        }}
                      >
                        <i className="fas fa-play-circle" />
                        {session
                          ? isPlayingSession
                            ? "Nghe trực tiếp"
                            : "Nghe lại"
                          : "Nghe thử"}
                      </PrimaryButton>
                      <div style={{ margin: "8px 0" }}>
                        Tổng thời lượng:{" "}
                        <span style={{ color: "#5180FB" }}>
                          {formatDuration(
                            session ? session.duration : eBulletin?.duration
                          )}
                        </span>
                      </div>
                      <PreviewAudio
                        url={sessionURL}
                        title={
                          session
                            ? isPlayingSession
                              ? "Nghe trực tiếp"
                              : "Nghe lại"
                            : "Nghe thử"
                        }
                        show={sessionURLPlaying}
                        toggle={() => {
                          setSessionURLPlaying(!sessionURLPlaying);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="Regular_13 col-12 col-xl-4"
                  style={{ marginTop: 10 }}
                >
                  <CurvedTab
                    titles={[t("BulletinGeneralInfo"), t("Area")]}
                    activeIndex={activeTab}
                    onChangeTab={(newTabIndex) => setActiveTab(newTabIndex)}
                  />
                  <div
                    className={`bg-white ${activeTab !== 0 ? "d-none" : ""}`}
                    style={{
                      border: "1px solid #DBE3EF",
                      borderTop: "none",
                    }}
                  >
                    <div className="border-top">
                      <AttributeRow>
                        <AttributeTitle>Loại bản tin</AttributeTitle>
                        <AttributeInput>
                          <SelectSort
                            valueBtnStyle={{ width: "100%" }}
                            selections={BULLETIN_TYPE}
                            currentValue={
                              _.find(BULLETIN_TYPE, { value: loaiBanTin })
                                ?.display || "Chọn"
                            }
                            onSelect={(item) => {
                              setLoaiBanTin(item.value);
                            }}
                            disable={disableAll}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Loại lĩnh vực</AttributeTitle>
                        <AttributeInput>
                          <SelectSort
                            valueBtnStyle={{ width: "100%" }}
                            selections={BULLETIN_CATEGORY}
                            currentValue={
                              _.find(BULLETIN_CATEGORY, { value: loaiLinhVuc })
                                ?.display || "Chọn"
                            }
                            onSelect={(item) => {
                              setLoaiLinhVuc(item.value);
                            }}
                            disable={disableAll}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Ngày xuất bản</AttributeTitle>
                        <AttributeInput>
                          <input
                            className="form-control"
                            disabled={true}
                            value={
                              eBulletin?.status !== "REJECTED" && approveDate
                                ? approveDate.format("DD/MM/YYYY HH:mm")
                                : "--/--/----"
                            }
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>ID</AttributeTitle>
                        <AttributeInput>
                          <input
                            className="form-control"
                            disabled={true}
                            defaultValue={id}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Tác Giả</AttributeTitle>
                        <AttributeInput>
                          <input
                            className="form-control"
                            disabled={true}
                            defaultValue={creator}
                          />
                        </AttributeInput>
                      </AttributeRow>
                    </div>
                  </div>
                  <div
                    className={`${activeTab !== 1 ? "d-none" : ""}`}
                    style={{
                      border: "1px solid #DBE3EF",
                      borderTop: "none",
                    }}
                  >
                    {/*khu vuc phat*/}
                    <div
                      className="bg-white border-bottom"
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <DevicesPicker
                        area={area}
                        onAreaChange={setArea}
                        daiTruyenThanh={daiTruyenThanh}
                        onDaiTruyenThanhChange={setDaiTruyenThanh}
                        speakers={speakers}
                        onSpeakersChange={(speakerList) => {
                          setSpeakers(
                            _.sortBy(speakerList, "MaDaiTruyenThanh")
                          );
                        }}
                        disableAll={disableAll}
                      />
                    </div>
                    <div
                      style={{
                        padding: 10,
                      }}
                    >
                      <div className="bg-white border d-flex align-items-center flex-wrap">
                        <AttributeRow>
                          <AttributeTitle>Khu vực phát</AttributeTitle>
                          <SelectArea
                            className={"flex-grow-1"}
                            onSelect={(selectedArea) => {
                              setArea(selectedArea);
                              if (selectedArea.MaKhuVuc != area?.MaKhuVuc) {
                                setDaiTruyenThanh(null);
                                setSpeakers([]);
                              }
                            }}
                            selectedArea={area}
                            rootAreaId={currentUser.MaKhuVuc}
                            isDisabled={disableAll}
                          />
                        </AttributeRow>
                      </div>
                      <div className={`${speakers.length ? "" : "d-none"}`}>
                        <p style={{ margin: "12px 0" }}>
                          Danh sách thiết bị được chọn
                        </p>
                        <div className="border">
                          {speakers.map((speaker, i) => {
                            let title = `${speaker.TenThietBi} - ${speaker["TenDaiTuyenThanh"]}`;
                            return (
                              <div
                                key={speaker.id}
                                title={title}
                                className={`bg-white text-primary ${
                                  i !== speakers.length - 1
                                    ? "border-bottom"
                                    : ""
                                } d-flex`}
                              >
                                <span
                                  className="text-truncate flex-shrink-1"
                                  style={{ padding: "12px 0 12px 16px" }}
                                >
                                  {title}
                                </span>
                                <span
                                  className="flex-shrink-0 text-danger d-flex justify-content-center align-items-center"
                                  style={{
                                    padding: "12px 16px 12px 10px",
                                    cursor: disableAll ? "default" : "pointer",
                                    opacity: 0.7,
                                  }}
                                  onClick={() => {
                                    if (disableAll) return;
                                    speakers.splice(i, 1);
                                    setSpeakers([...speakers]);
                                  }}
                                >
                                  <i className="text-danger fas fa-times-square" />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*reject reason modal*/}
            <Modal
              style={{ maxWidth: 400 }}
              centered={true}
              isOpen={rejectReasonModalShow}
              toggle={toggleRejectReasonModal}
              className={"w-100"}
            >
              <ModalBody className="p-0">
                <div
                  className="text-center font-weight-boldest text-uppercase border-bottom text-truncate"
                  style={{ padding: 20 }}
                  title="Lý do từ chối"
                >
                  Lý do từ chối
                </div>
                <div style={{ padding: 20 }}>
                  <textarea
                    className="form-control"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    rows={5}
                    placeholder={"Nhập lý do từ chối"}
                  />
                </div>
              </ModalBody>

              <ModalFooter style={{ padding: "10px 20px" }}>
                <div className="d-flex m-0 w-100">
                  <div
                    style={{ paddingLeft: 0, paddingRight: 8 }}
                    className="col"
                  >
                    <button
                      onClick={toggleRejectReasonModal}
                      style={{ height: 40 }}
                      className="btn btn-secondary border w-100"
                    >
                      Hủy
                    </button>
                  </div>

                  <div
                    style={{ paddingLeft: 8, paddingRight: 0 }}
                    className="col"
                  >
                    <PrimaryButton
                      onClick={() => approveBulletin(false)}
                      style={{ height: 40 }}
                      className={`w-100 btn-danger`}
                    >
                      Xác Nhận
                    </PrimaryButton>
                  </div>
                </div>
              </ModalFooter>
            </Modal>
          </AppCard>
        </div>
      </AppPage>
      {
        <PlayDirect
          show={playingDirect}
          onClose={setPlayingDirect}
          playDirect={playDirectStatus}
          micID={micID}
          banTinId={banTinID}
        />
      }
    </>
  );
}

export default BulletinEmergencyCreateScreen;
