import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Global from "general/Global";

import useSort from "hooks/useSort";

import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";

import "./index.scss";

import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import { useHistory } from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import ConfirmModal from "../../pages/BulletinManager/components/ConfirmModal";
import trashIcon from "../../assets/images/trash-round-bg.svg";
import moment from "moment";
import _ from "lodash";
import { BULLETIN_WEEK_DAYS } from "../../general/constants/Types";
import bulletinApi from "../../api/bulletinApi";
import bulletinBoardApi from "../../api/bulletinBoardApi";

/**
 * Props:
 * * getEBulletinsAPI (`func`): hàm nhận query-string từ bên trong để gọi api
 * * searchText (`string`): string to search on
 *
 * Example:
 * ```js
 * import stationApi from '...';
 *
 * <DevicesTable
 getEBulletinsAPI={(params) => stationApi.getStationDevices(station.id, params)}
 searchText={searchText}
 />
 * ```
 */
RadioBulletinScheduleTable.propTypes = {
  selectedItems: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
};

RadioBulletinScheduleTable.defaultProps = {
  selectedItems: [],
  onSelectedItemsChange: () => {},
};

function RadioBulletinScheduleTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    getEBulletinsAPI,
    searchText,
    currentAreaId,
    tableName,
    selectedItems: selectedBulletins,
    onSelectedItemsChange: setSelectedBulletins,
    isBoardBulletin = false,
  } = props;
  const history = useHistory();
  const confirmModalRef = useRef();
  const areas = useSelector((state) => state.area.allAreas);

  const [isLoading, setIsLoading] = useState(true);
  const [bulletinTotal, setBulletinTotal] = useState(0);
  const [bulletins, setBulletins] = useState([]);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);

  const { directionIndicator, sortOnClick } = useSort(
    bulletins,
    (sortedArray) => setBulletins(sortedArray)
  );

  // MARK: --- Functions ---

  async function getBulletinSchedules() {
    setIsLoading(true);
    const res = await getEBulletinsAPI({
      page: page - 1,
      limit: step,
      q: searchText,
      orderField: "",
      orderMode: "ASC",
    });
    // console.log(res);
    // return;
    if (res && res?.data?.MoTa === "ThanhCong") {
      setBulletins(res?.data?.NoiDung?.DanhSachLichPhat);
      setBulletinTotal(res?.data?.NoiDung?.count);
      if (res?.data?.NoiDung?.DanhSachLichPhat?.length === 0 && page > 1) {
        setPage(page - 1);
      }
    } else {
      setBulletins([]);
      setBulletinTotal(0);
    }
    setIsLoading(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (areas.length === 0) {
      dispatch(thunkGetAreas());
    }
  }, []);

  useEffect(() => {
    getBulletinSchedules();
  }, [searchText, page, step, currentAreaId, tableName]);

  function handleSetting(bulletinId) {
    if (isBoardBulletin)
      return history.push(`/bulletins/edit/bulletin-board/${bulletinId}`);
    else history.push(`/bulletins/edit/speaker/${bulletinId}`);
  }

  async function handleDelete(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Xóa lịch phát`,
        <div style={{ padding: 16 }} className="text-center">
          <img src={trashIcon} style={{ marginBottom: 20 }} />
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn xóa lịch phát
                <span className="text-danger">
                  &nbsp;{bulletin.TenLichPhat}
                </span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn xoá {selectedBulletins.length} lịch phát đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let deleteCall = isBoardBulletin
        ? bulletinBoardApi.deleteBulletins
        : bulletinApi.deleteBulletins;
      await deleteCall(
        batchDelete ? selectedBulletins : [bulletin.LichPhatID]
      ).then((data) => {
        if (data.data.status === 0) {
          ToastHelper.showSuccess("Xoá lịch phát thành công");
          getBulletinSchedules();
          if (batchDelete) {
            setSelectedBulletins([]);
          }
        }
      });
    } catch (e) {}
  }

  async function handleStop(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Dừng lịch phát`,
        <div style={{ padding: 16 }} className="text-center">
          <img src={trashIcon} style={{ marginBottom: 20 }} />
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn dừng lịch phát
                <span className="text-danger">
                  &nbsp;{bulletin.TenLichPhat}
                </span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn dừng {selectedBulletins.length} lịch phát đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let stopCall = isBoardBulletin
        ? bulletinBoardApi.stopBulletins
        : bulletinApi.stopBulletins;
      await stopCall(
        batchDelete ? selectedBulletins : [bulletin.LichPhatID]
      ).then((data) => {
        if (data.data.status === 0) {
          ToastHelper.showSuccess("Dừng lịch phát thành công");
          getBulletinSchedules();
          if (batchDelete) {
            setSelectedBulletins([]);
          }
        }
      });
    } catch (e) {}
  }

  return (
    <>
      <DataTable
        className="bulletin-datatable"
        cols={[0, 40, 15, 15, 15, 150, 0, 0]}
        headItems={[
          // Name
          <CheckBox
            checked={selectedBulletins.length == bulletins.length}
            onCheck={() => {
              // setSelectedBulletins(_.unionBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.union(selectedBulletins, _.map(bulletins, "LichPhatID"))
              );
            }}
            onUncheck={() => {
              // setSelectedBulletins(_.differenceBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.difference(selectedBulletins, _.map(bulletins, "LichPhatID"))
              );
            }}
          />,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TenLichPhat")}
          >
            <p>
              Tên lịch phát
              {directionIndicator("TenLichPhat")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TenNguon")}
          >
            <p>
              Tên nguồn
              {directionIndicator("TenNguon")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TenDich")}
          >
            <p>
              Tên đích
              {directionIndicator("TenDich")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TenDiaBanTao")}
          >
            <p>
              Tên địa bàn tạo
              {directionIndicator("TenDiaBanTao")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("NguoiTao")}
          >
            <p>
              Tên người tạo
              {directionIndicator("NguoiTao")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("DanhSachDiaBanNhan")}
          >
            <p>
              Danh sách địa bàn nhận
              {directionIndicator("DanhSachDiaBanNhan")}
            </p>
          </div>,

          // Area
          <div className="w-100" onClick={() => sortOnClick("TrangThai")}>
            <p>
              TRẠNG THÁI
              {directionIndicator("status")}
            </p>
          </div>,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("LyDo")}
          >
            <p>
              Lý do
              {directionIndicator("LyDo")}
            </p>
          </div>,

          t("Manage"),
        ]}
        // row
        dataItems={bulletins?.map((item) => {
          let startDate = moment(item?.startDate),
            endDate = item?.endDate ? moment(item.endDate) : null;
          let isRowCheck = selectedBulletins.includes(item.LichPhatID);
          return {
            isRowCheck: isRowCheck,
            rowClick: () => {},
            data: [
              {
                comp: (
                  <CheckBox
                    checked={isRowCheck}
                    onCheck={() =>
                      setSelectedBulletins([
                        ...selectedBulletins,
                        item.LichPhatID,
                      ])
                    }
                    onUncheck={() => {
                      let currentSelectedBulletins = [...selectedBulletins];
                      let itemIndex = currentSelectedBulletins.indexOf(
                        item.LichPhatID
                      );
                      if (itemIndex !== -1) {
                        currentSelectedBulletins.splice(itemIndex, 1);
                        setSelectedBulletins(currentSelectedBulletins);
                      }
                    }}
                  />
                ),
              },
              {
                comp: (
                  <div key={item.LichPhatID} className="">
                    <div
                      className="Bold_13 text-primary text-emphasis q-max-line-1"
                      title={item.TenLichPhat}
                      style={{ marginBottom: 4 }}
                    >
                      {item.TenLichPhat}
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div style={{ wordBreak: "break-all", maxWidth: "200px" }}>
                    <p className="Regular_13 w-100 text-nowrap">
                      {item?.TenNguon || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div style={{ wordBreak: "break-all", maxWidth: "200px" }}>
                    <p className="Regular_13 w-100 text-nowrap">
                      {item?.TenDich || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div style={{ wordBreak: "break-all", maxWidth: "200px" }}>
                    <p className="Regular_13 w-100 text-nowrap">
                      {item?.TenDiaBanTao || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div style={{ wordBreak: "break-all", maxWidth: "200px" }}>
                    <p className="Regular_13 w-100 text-nowrap">
                      {item?.NguoiDung?.Ten || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div>
                    <p className="Regular_13 w-100 text-nowrap truncate-custom">
                      {item?.DanhSachDiaBanNhan?.map(
                        (diaBan) => diaBan?.TenDich
                      )
                        ?.filter((diaBan) => !!diaBan)
                        ?.join(", ") || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div
                    className={`bulletin-status-badge bulletin-status-badge-${item?.TrangThai?.toLowerCase()}`}
                  >
                    {t(item?.TrangThai || "")}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div style={{ wordBreak: "break-all", maxWidth: "200px" }}>
                    <p className="Regular_13 w-100 text-nowrap">
                      {item?.LyDo || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.LichPhatID);
                },
              },
              {
                comp: (
                  <div className="Manage" style={{ width: 80 }}>
                    <i
                      className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                      onClick={() => {
                        handleSetting(item.LichPhatID);
                      }}
                    ></i>
                    <i
                      className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                      onClick={() => handleDelete(item)}
                    ></i>
                    {item.TrangThai === "APPROVED" && (
                      <i
                        className="fas fa-stop-circle cursor-pointer text-danger"
                        onClick={() => handleStop(item)}
                      ></i>
                    )}
                  </div>
                ),
              },
            ],
          };
        })}
        step={step}
        onRefresh={() => getBulletinSchedules()}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white rounded-bottom"
        total={bulletinTotal}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
      <ConfirmModal
        ref={confirmModalRef}
        confirmButtonClass={"btn btn-danger"}
        confirmButtonText={"Xác Nhận"}
      />
    </>
  );
}

export default RadioBulletinScheduleTable;
