import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { thunkGetProvider } from "pages/ProviderManager/providerSlice";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import { thunkGetStations } from "pages/RadioStationManager/stationSlice";

DataCommonListener.propTypes = {};

const sTag = "[DataCommonListener]";

function DataCommonListener(props) {
  const { current } = useSelector((state) => state.auth);
  // MARK: --- Params ---
  const dispatch = useDispatch();

  // MARK: --- Hooks ---
  useEffect(() => {
    // Get common data
    if (current) {
      dispatch(thunkGetProvider());
      dispatch(thunkGetAreas());
      dispatch(thunkGetStations());
    }
  }, [current]);

  return <></>;
}

export default DataCommonListener;
