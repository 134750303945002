export const BULLETIN_SOURCE_TYPES = [
  {
    type: "FILE",
    title: "BulletinSourceFile",
    icon: "file-music",
    active: true,
  },
  {
    type: "MIC",
    title: "BulletinSourceMic",
    icon: "microphone",
    active: false,
  },
  {
    type: "DEVICE",
    title: "BulletinSourceDevice",
    icon: "router",
    active: false,
  },
  {
    type: "DOCUMENT",
    title: "BulletinSourceDocument",
    icon: "comment-alt-edit",
    active: false,
  },
  {
    type: "RELAY",
    title: "BulletinSourceRelay",
    icon: "diagram-next",
    active: false,
  },
];

export const BULLETIN_BOARD_SOURCE_TYPES = [
  { type: "IMAGE", title: "Hình ảnh", icon: "image", active: true },
  { type: "VIDEO", title: "Video", icon: "film", active: false },
  { type: "TEXT", title: "Văn bản ", icon: "text", active: false },
];

export const BULLETIN_PLAY_MODE = [
  { value: "FIX", display: "Cố định" },
  { value: "SCHEDULE", display: "Theo lịch" },
  { value: "NOW", display: "Phát ngay", default: true },
  { value: "EMERGENCY", display: "Khẩn cấp" },
];

export const BULLETIN_REPEAT_TYPE = [
  {
    value: "None",
    display: "Một lần",
    default: true,
    modes: ["NOW", "EMERGENCY"],
  },
  { value: "Day", display: "Hàng ngày", modes: ["FIX"] },
  { value: "Week", display: "Hàng tuần", modes: ["SCHEDULE"] },
  { value: "Month", display: "Hàng tháng", modes: ["SCHEDULE"] },
];

export const BULLETIN_PRIORITY = [
  { value: "1", display: "Bản tin khẩn cấp" },
  { value: "2", display: "Bản tin ưu tiên" },
  { value: "3", display: "Bản tin thông thường", default: true },
];

export const BULLETIN_WEEK_DAYS = [
  { value: 2, title: "Thứ 2" },
  { value: 3, title: "Thứ 3" },
  { value: 4, title: "Thứ 4" },
  { value: 5, title: "Thứ 5" },
  { value: 6, title: "Thứ 6" },
  { value: 7, title: "Thứ 7" },
  { value: 1, title: "Chủ Nhật" },
];

export const SignalConnectionType = [
  { value: "TrucTiep", display: "Trực tiếp" },
  { value: "GianTiep", display: "Gián tiếp" },
];

export const BULLETIN_TYPE = [
  { value: "1", display: "Bản tin ký tự" },
  { value: "2", display: "Bản tin hình ảnh" },
  { value: "3", display: "Bản tin âm thanh" },
  { value: "4", display: "Bản tin video" },
  { value: "5", display: "Bản tin trực tiếp" },
];

export const BULLETIN_CATEGORY = [
  { value: "1", display: "Thông tin chung" },
  { value: "2", display: "Thông tin đột xuất" },
  {
    value: "3",
    display:
      "Thông tin về đường lối, chủ trương của Đảng, chính sách, pháp luật của Nhà nước",
  },
  {
    value: "4",
    display: "Thông tin chỉ đạo, điều hành của cấp ủy, chính quyền cơ sở",
  },
  {
    value: "5",
    display:
      "Thông tin về kiến thức khoa học, kỹ thuật, kinh tế, văn hóa, xã hội",
  },
  {
    value: "6",
    display: "Thông tin về gương người tốt, việc tốt, điển hình tiên tiến",
  },
];

export const DIGITALSIGNAGE_VUNG_PHAT = [
  {
    value: "0",
    display: "Toàn màn hình",
  },
  {
    value: "1",
    display: "Vùng thông báo",
  },
  {
    value: "2",
    display: "Vùng bên trái",
  },
  {
    value: "3",
    display: "Vùng bên phải",
  },
];
