import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import BulletinCreateScreen from "./screens/BulletinCreate";
import BulletinScheduleScreen from "./screens/BulletinSchedule";
import eBulletinHistoryScreen from "./screens/BulletinHistory/eBulletinHistory";
import RadioHistoryScreen from "./screens/BulletinHistory/radioBulletinHistory";
import BulletinBoardScreen from "./screens/BulletinBoard";
import BulletinListScreen from "./screens/BulletinList";
import BulletinBoardListScreen from "./screens/BulletinManager";
import BulletinEmergencyBoardScreen from "./screens/BulletinEmergencyBoard";
import BulletinEmergencyCreateScreen from "./screens/BulletinEmergencyCreate";

function BulletinManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/list/speaker`}
        component={BulletinListScreen}
      />
      <Route
        exact
        path={`${match.url}/list/bulletin-board`}
        component={BulletinBoardListScreen}
      />
      <Route
        exact
        path={`${match.url}/edit/speaker`}
        component={BulletinCreateScreen}
      />
      <Route
        exact
        path={`${match.url}/edit/emergency-speaker`}
        component={BulletinEmergencyCreateScreen}
      />

      <Route
        exact
        path={`${match.url}/edit/bulletin-board`}
        component={BulletinBoardScreen}
      />

      <Route
        exact
        path={`${match.url}/edit/emergency-bulletin-board`}
        component={BulletinEmergencyBoardScreen}
      />
      <Route
        exact
        path={`${match.url}/schedule`}
        component={BulletinScheduleScreen}
      />
      <Route
        exact
        path={`${match.url}/history/radio`}
        component={RadioHistoryScreen}
      />
      <Route
        exact
        path={`${match.url}/history/bulletin-board`}
        component={eBulletinHistoryScreen}
      />
      <Route
        exact
        path={`${match.url}/edit/speaker/:BanTinID`}
        render={(props) => (
          <BulletinCreateScreen key={props.match.params.BanTinID} {...props} />
        )}
      />
      <Route
        exact
        path={`${match.url}/edit/emergency-speaker/:BanTinID`}
        render={(props) => (
          <BulletinEmergencyCreateScreen
            key={props.match.params.BanTinID}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/edit/bulletin-board/:BanTinDienTuID`}
        render={(props) => (
          <BulletinBoardScreen
            key={props.match.params.BanTinDienTuID}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/edit/emergency-bulletin-board/:BanTinDienTuID`}
        render={(props) => (
          <BulletinEmergencyBoardScreen
            key={props.match.params.BanTinDienTuID}
            {...props}
          />
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default BulletinManager;
