import { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import Tippy from "@tippyjs/react/headless";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { useResizeDetector } from "react-resize-detector";

import styles from "./SelectSort.module.scss";

const Box = styled(animated.div)`
  padding: 4px 0px;
  border-radius: 4px;
`;

const cx = classNames.bind(styles);

function SelectSort({
  currentValue,
  selections = [],
  style,
  className,
  onSelect,
  valueBtnStyle,
  disable,
}) {
  const [show, setShow] = useState(false);
  const config = { tension: 600 };
  const initialStyles = { opacity: 1, transform: "scaleY(0)" };
  const [props, setSpring] = useSpring(() => initialStyles);
  const { width, ref } = useResizeDetector();
  const [height, setHeight] = useState(0);

  const onMount = () => {
    console.log("show");
    setSpring({
      opacity: 1,
      transform: "scaleY(1)",
      onRest: () => {},
      config,
    });
  };

  const onHide = ({ unmount }) => {
    setSpring({
      ...initialStyles,
      onRest: unmount,
      config: { ...config, clamp: true },
    });
  };

  const wHeight = window.innerHeight;

  return (
    <div ref={ref} style={style} className={className + " " + cx("t")}>
      <Tippy
        interactive={true}
        visible={show}
        placement={"bottom-end"}
        onClickOutside={() => setShow(false)}
        animation={true}
        onMount={onMount}
        onHide={onHide}
        render={(attrs) => (
          <Box
            className={cx("wrapper")}
            style={{
              ...props,
              transformOrigin:
                wHeight - height >
                (selections.length > 10 ? 300 : selections.length * 38)
                  ? "top"
                  : "bottom",
            }}
            tabIndex={"-1"}
            {...attrs}
          >
            {selections?.map((item, index) => (
              <div
                key={item.id ?? index}
                className={
                  _.isEqual(currentValue, item.display)
                    ? disable
                      ? cx("item-selected-disable")
                      : cx("item-selected")
                    : disable
                    ? cx("item-disable")
                    : cx("item")
                }
                style={{
                  width: width,
                }}
                onClick={() => {
                  if (disable) {
                    return;
                  }
                  onSelect(item);
                  setShow(false);
                }}
              >
                <div className={cx("display")}>{item.display}</div>
              </div>
            ))}
          </Box>
        )}
      >
        <div
          onMouseDown={(e) => {
            if (!show) {
              setHeight(e.clientY ?? 0);
            }
          }}
          className={cx("btn")}
          style={{
            borderColor: show ? "#69b3ff" : undefined,
            ...valueBtnStyle,
          }}
          onClick={() => setShow(!show)}
        >
          <div className={cx("label")}>{currentValue}</div>
          <i
            className="far fa-angle-down position-absolute top-50 translate-middle-y"
            style={{ right: 12 }}
          ></i>
        </div>
      </Tippy>
    </div>
  );
}

export { SelectSort };
