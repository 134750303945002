import React from "react";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import "./styles.scss";
// import "./App.css";
import UserHelper from "general/helpers/UserHelper";
import Loading from "components/Loading";
import NotFound from "components/NotFound";
import GuestRoute from "routes/GuestRoute";
import AuthPage from "pages/Login";
import { thunkGetCurrentUserInfo } from "pages/Login/authSlice";
import Utils from "general/utils/Utils";
import PrivateRoute from "routes/PrivateRoute";
import Global from "general/Global";
import DeviceManager from "pages/DeviceManager";
import AccountManager from "pages/AccountManager";
import BulletinManager from "./pages/BulletinManager";
import GroupManager from "./pages/GroupManager";
import useWebSocket from "react-use-websocket";
import Report from "./pages/Report";
import Feedback from "./pages/Feedback";
import Category from "./pages/Category";

import DeviceMap from "./pages/DeviceMap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AccountManagerHomeScreen from "pages/AccountManager/screens/AccountManagerHomeScreen";
import History from "pages/History";
import ResetPassword from "pages/Login/screens/ResetPassword";
import Dashboard from "pages/Dashboard";
import RadioStationManager from "pages/RadioStationManager";
import ProviderManager from "pages/ProviderManager";
import AreaManager from "pages/AreaManager";
import IdleTimeOutHandler from "idleTimeOut/idleTimeoutHandle";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { thunkGetProvider } from "pages/ProviderManager/providerSlice";
import DataCommonListener from "general/listeners/DataCommonListener";

window._ = _;
global._ = _;

window.jQuery = window.$ = require("jquery");
window.moment = require("moment");
global.moment = window.moment;

// Load plugins
require("assets/plugins/ktutil");
require("assets/plugins/ktoffcanvas");
require("assets/plugins/kttoggle");
require("assets/plugins/ktmenu");
require("assets/plugins/ktcookie");
require("assets/plugins/ktcard");
require("assets/plugins/ktwizard");
require("assets/plugins/ktapp");

// Lazy load - Code splitting

// const Dashboard = React.lazy(() => import("./pages/Dashboard"));
// const RadioStationManager = React.lazy(() =>
//   import("./pages/RadioStationManager")
// );
// const ProviderManager = React.lazy(() => import("./pages/ProviderManager"));
// const AreaManager = React.lazy(() => import("./pages/AreaManager"));
function App() {
  // MARK: --- Params ---
  const sTag = "[App]";
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const { sendMessage, readyState, getWebSocket } = useWebSocket(
  //   `ws://127.0.0.1:3000/websockets?wsId=${12345}&banTinID=${"12345"}&type=RTMP`,
  //   {
  //     onMessage: (message) => {
  //       console.log(message);
  //       // setTimeout(() => {
  //       //   sendMessage("hello");
  //       // }, 2000);
  //     },
  //     filter: () => false,
  //     shouldReconnect: (_closeEvent) => true, //what does the close event look like when caused by the 2 hour limit? Is there a different error code that you could use? I would look into returning false in this case and adding the following option:
  //     retryOnError: true, //With this true, and returning false in the 'shouldReconnect' will avoid trying to reconnect twice for the same event
  //     onClose: () => console.log("closing"),
  //     onError: (err) => console.log("error", err),
  //   }
  // );

  // MARK: --- Hooks ---
  // Load user info when reload
  useEffect(() => {
    if (
      Global.refreshToken &&
      Global.refreshToken.length > 0 &&
      Global.accessToken &&
      Global.accessToken.length > 0
    ) {
      dispatch(thunkGetCurrentUserInfo({}));
    }
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <div className="d-flex align-items-center justify-content-center pt-10">
            <Loading message={t("Loading")} />
          </div>
        }
      >
        <IdleTimeOutHandler
          onActive={() => {
            console.log("[IdleTimeOutHandle] onActive........");
          }}
          onIdle={() => {
            console.log("[IdleTimeOutHandle] onIdle........");
            localStorage.removeItem(PreferenceKeys.accessToken);
            localStorage.removeItem(PreferenceKeys.refreshToken);
            Global.accessToken = "";
            Global.refreshToken = "";
            history.push("/auth/sign-in");
            // store.dispatch(logout());
          }}
        />
        <Switch>
          <Redirect exact from="/" to="/dashboard" />

          {/* Guest route */}
          <Route path="/auth" component={AuthPage} />
          <Route path="/reset-password/:resetToken" component={ResetPassword} />
          {/* Private route */}
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute
            path="/radio-stations"
            component={RadioStationManager}
          />
          <PrivateRoute path="/providers" component={ProviderManager} />
          <PrivateRoute path="/area-manager" component={AreaManager} />
          <PrivateRoute path="/device-manager" component={DeviceManager} />
          <PrivateRoute path="/account" component={AccountManager} />
          <PrivateRoute path="/bulletins" component={BulletinManager} />
          <PrivateRoute path="/users/group" component={GroupManager} />
          <PrivateRoute
            path="/users/manager"
            component={AccountManagerHomeScreen}
          />
          <PrivateRoute path="/category" component={Category} />
          <PrivateRoute path="/device-map" component={DeviceMap} />
          <PrivateRoute path="/reports" component={Report} />
          <PrivateRoute path="/feedback" component={Feedback} />
          <PrivateRoute path="/category" component={Category} />
          <PrivateRoute path="/users/history" component={History} />
          {/* Not found */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>

      {/* Toast */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <DataCommonListener />
    </>
  );
}

export default App;
