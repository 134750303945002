import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Global from "general/Global";

import useSort from "hooks/useSort";

import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";

import "./index.scss";

import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import { useHistory } from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import ConfirmModal from "../../pages/BulletinManager/components/ConfirmModal";
import trashIcon from "../../assets/images/trash-round-bg.svg";
import moment from "moment";
import _ from "lodash";
import { BULLETIN_WEEK_DAYS } from "../../general/constants/Types";
import bulletinApi from "../../api/bulletinApi";
import bulletinBoardApi from "../../api/bulletinBoardApi";

/**
 * Props:
 * * getEBulletinsAPI (`func`): hàm nhận query-string từ bên trong để gọi api
 * * searchText (`string`): string to search on
 *
 * Example:
 * ```js
 * import stationApi from '...';
 *
 * <DevicesTable
 getEBulletinsAPI={(params) => stationApi.getStationDevices(station.id, params)}
 searchText={searchText}
 />
 * ```
 */
EBulletinTable.propTypes = {
  selectedItems: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
};

EBulletinTable.defaultProps = {
  selectedItems: [],
  onSelectedItemsChange: () => {},
};

function EBulletinTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    getEBulletinsAPI,
    searchText,
    currentAreaId,
    tableName,
    selectedItems: selectedBulletins,
    onSelectedItemsChange: setSelectedBulletins,
    isBoardBulletin = false,
  } = props;
  const history = useHistory();
  const confirmModalRef = useRef();
  const areas = useSelector((state) => state.area.allAreas);

  const [isLoading, setIsLoading] = useState(true);
  const [bulletinTotal, setBulletinTotal] = useState(0);
  const [bulletins, setBulletins] = useState([]);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);

  const { directionIndicator, sortOnClick } = useSort(
    bulletins,
    (sortedArray) => setBulletins(sortedArray)
  );

  // MARK: --- Functions ---

  async function getDigitalSignageBulletins() {
    setIsLoading(true);
    const res = await getEBulletinsAPI({
      page: page - 1,
      limit: step,
      q: searchText,
      orderField: "",
      orderMode: "ASC",
    });
    // console.log(res);
    // return;
    if (res && res?.data?.MoTa === "ThanhCong") {
      setBulletins(res?.data?.NoiDung?.DanhSachBanTin);
      setBulletinTotal(res?.data?.NoiDung?.count);
      if (res?.data?.NoiDung?.DanhSachBanTin?.length === 0 && page > 1) {
        setPage(page - 1);
      }
    } else {
      setBulletins([]);
      setBulletinTotal(0);
    }
    setIsLoading(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (areas.length === 0) {
      dispatch(thunkGetAreas());
    }
  }, []);

  useEffect(() => {
    getDigitalSignageBulletins();
  }, [searchText, page, step, currentAreaId, tableName]);

  function handleSetting(bulletinId) {
    return history.push(`/bulletins/edit/bulletin-board/${bulletinId}`);
  }

  async function handleDelete(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Xóa bản tin`,
        <div style={{ padding: 16 }} className="text-center">
          <img src={trashIcon} style={{ marginBottom: 20 }} />
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn xóa bản tin
                <span className="text-danger">&nbsp;{bulletin.title}</span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn xoá {selectedBulletins.length} bản tin đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let deleteCall = isBoardBulletin
        ? bulletinBoardApi.deleteBulletins
        : bulletinApi.deleteBulletins;
      await deleteCall(
        batchDelete ? selectedBulletins : [bulletin.BanTinDienTuID]
      ).then((data) => {
        if (data.data.status === 0) {
          ToastHelper.showSuccess("Xoá bản tin thành công");
          getDigitalSignageBulletins();
          if (batchDelete) {
            setSelectedBulletins([]);
          }
        }
      });
    } catch (e) {}
  }

  async function handleStop(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Dừng bản tin`,
        <div style={{ padding: 16 }} className="text-center">
          <img src={trashIcon} style={{ marginBottom: 20 }} />
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn dừng bản tin
                <span className="text-danger">&nbsp;{bulletin.title}</span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn dừng {selectedBulletins.length} bản tin đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let stopCall = isBoardBulletin
        ? bulletinBoardApi.stopBulletins
        : bulletinApi.stopBulletins;
      await stopCall(
        batchDelete ? selectedBulletins : [bulletin.BanTinDienTuID]
      ).then((data) => {
        if (data.data.status === 0) {
          ToastHelper.showSuccess("Dừng bản tin thành công");
          getDigitalSignageBulletins();
          if (batchDelete) {
            setSelectedBulletins([]);
          }
        }
      });
    } catch (e) {}
  }

  return (
    <>
      <DataTable
        className="bulletin-datatable"
        cols={[0, 40, 15, 15, 15, 15, 0, 0]}
        headItems={[
          // Name
          <CheckBox
            checked={selectedBulletins.length == bulletins.length}
            onCheck={() => {
              // setSelectedBulletins(_.unionBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.union(selectedBulletins, _.map(bulletins, "BanTinDienTuID"))
              );
            }}
            onUncheck={() => {
              // setSelectedBulletins(_.differenceBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.difference(
                  selectedBulletins,
                  _.map(bulletins, "BanTinDienTuID")
                )
              );
            }}
          />,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TieuDe")}
          >
            <p>
              Tiêu Đề
              {directionIndicator("TieuDe")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("NoiDung")}
          >
            <p>
              Nội dung
              {directionIndicator("NoiDung")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("ThoiGianSanXuat")}
          >
            <p>
              Thời gian sản xuất
              {directionIndicator("ThoiGianSanXuat")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("ThoiLuong")}
          >
            <p>
              Thời lượng
              {directionIndicator("ThoiLuong")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("DanhSachNgayPhat")}
          >
            <p>
              Danh sách ngày phát
              {directionIndicator("DanhSachNgayPhat")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("DanhSachDiaBanNhan")}
          >
            <p>
              Danh sách địa bàn nhận
              {directionIndicator("DanhSachDiaBanNhan")}
            </p>
          </div>,

          // <div
          //   className="sort-direction w-100 cursor-pointer"
          //   onClick={() => sortOnClick("startDate")}
          // >
          //   <p>
          //     Lịch Phát
          //     {directionIndicator("startDate")}
          //   </p>
          // </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("LoaiBanTin")}
          >
            <p>
              Loại Bản Tin
              {directionIndicator("LoaiBanTin")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("MucDoUuTien")}
          >
            <p>
              Mức độ ưu tiên
              {directionIndicator("MucDoUuTien")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("LoaiLinhVuc")}
          >
            <p>
              Loại Lĩnh Vực
              {directionIndicator("LoaiLinhVuc")}
            </p>
          </div>,

          // Volume
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TacGia")}
          >
            <p>
              Tác Giả
              {directionIndicator("TacGia")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("TenDiaBanTao")}
          >
            <p>
              Khu Vực
              {directionIndicator("TenDiaBanTao")}
            </p>
          </div>,
          // Area
          <div className="w-100" onClick={() => sortOnClick("TrangThai")}>
            <p>
              TRẠNG THÁI
              {directionIndicator("TrangThai")}
            </p>
          </div>,
          t("Manage"),
        ]}
        // row
        dataItems={bulletins.map((item) => {
          let startDate = moment(item.startDate),
            endDate = item.endDate ? moment(item.endDate) : null;
          let isRowCheck = selectedBulletins.includes(item.BanTinDienTuID);
          return {
            isRowCheck: isRowCheck,
            rowClick: () => {},
            data: [
              {
                comp: (
                  <CheckBox
                    checked={isRowCheck}
                    onCheck={() =>
                      setSelectedBulletins([
                        ...selectedBulletins,
                        item.BanTinDienTuID,
                      ])
                    }
                    onUncheck={() => {
                      let currentSelectedBulletins = [...selectedBulletins];
                      let itemIndex = currentSelectedBulletins.indexOf(
                        item.BanTinDienTuID
                      );
                      if (itemIndex !== -1) {
                        currentSelectedBulletins.splice(itemIndex, 1);
                        setSelectedBulletins(currentSelectedBulletins);
                      }
                    }}
                  />
                ),
              },
              {
                comp: (
                  <div key={item.BanTinDienTuID} className="">
                    <div
                      className="Bold_13 text-primary text-emphasis q-max-line-1"
                      title={item.TieuDe}
                      style={{ marginBottom: 4 }}
                    >
                      {item.TieuDe}
                    </div>
                    <div className="Regular_13 q-max-line-3">
                      {item.NoiDungTomTat}
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div style={{ width: "200px" }}>
                    <p className="Regular_13 truncate-custom">
                      {item?.NoiDung || ""}
                    </p>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div>
                    <div className="Regular_13 text-nowrap">
                      <span style={{ color: "#3ACE5A" }}>
                        {item?.ThoiGianSanXuat
                          ? moment
                              .unix(item?.ThoiGianSanXuat)
                              .format("DD/MM/YYYY")
                          : ""}
                      </span>
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div>
                    <div className="Regular_13 text-nowrap">
                      <span style={{ color: "#3ACE5A" }}>
                        {item?.ThoiLuong || ""}
                      </span>
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div>
                    <div className="Regular_13 text-nowrap truncate-custom">
                      {item?.DanhSachNgayPhat?.filter((ngay) => !!ngay)
                        ?.map((ngayPhat) =>
                          ngayPhat?.NgayPhat
                            ? moment
                                .unix(ngayPhat?.NgayPhat)
                                .format("DD/MM/YYYY")
                            : ""
                        )
                        ?.filter((ngay) => !!ngay)
                        ?.join(", ") || ""}
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div>
                    <div className="Regular_13 text-nowrap truncate-custom">
                      {item?.DanhSachDiaBanNhan?.filter((diaBan) => !!diaBan)
                        ?.map((diaBan) => diaBan?.TenDich)
                        ?.join(", ") || ""}
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              // {
              //   comp: (
              //     <div>
              //       <div className="Regular_13 text-nowrap">
              //         Từ&nbsp;
              //         <span style={{ color: "#3ACE5A" }}>
              //           {startDate.format("DD/MM/YYYY")}
              //         </span>
              //       </div>
              //       {!!endDate && (
              //         <div className="text-nowrap">
              //           Đến&nbsp;
              //           <span className="text-danger">
              //             {endDate.format("DD/MM/YYYY")}
              //           </span>
              //         </div>
              //       )}
              //     </div>
              //   ),
              //   onClick: () => {
              //     handleSetting(item.BanTinDienTuID);
              //   },
              // },
              {
                comp: (
                  <div className="Regular_13" style={{ minWidth: "150px" }}>
                    {item?.LoaiBanTin == 1 && "Bản tin ký tự"}
                    {item?.LoaiBanTin == 2 && "Bản tin hình ảnh"}
                    {item?.LoaiBanTin == 3 && "Bản tin âm thanh"}
                    {item?.LoaiBanTin == 4 && "Bản tin video"}
                    {item?.LoaiBanTin == 5 && "Bản tin trực tiếp"}
                    {item?.LoaiBanTin === "SCHEDULE" && (
                      <div className="Regular_13 text-nowrap">
                        Lặp lại vào
                        <div style={{ color: "#3ACE5A" }}>
                          {item.repeatType === "Week" &&
                            BULLETIN_WEEK_DAYS.reduce(function (
                              selectedWeekDay,
                              weekDay,
                              index
                            ) {
                              if (item.playingDay.includes(weekDay.value)) {
                                if (weekDay.value === 1) {
                                  //sunday
                                  selectedWeekDay.push(weekDay.title);
                                } else if (selectedWeekDay.length === 0) {
                                  // "Thứ"
                                  selectedWeekDay.push(weekDay.title);
                                } else {
                                  selectedWeekDay.push(weekDay.value);
                                }
                              }
                              return selectedWeekDay;
                            },
                            []).join(", ")}
                          {item.repeatType === "Month" &&
                            item.playingDay.join(", ")}
                        </div>
                      </div>
                    )}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div className="Regular_13" style={{ minWidth: "150px" }}>
                    {item?.MucDoUuTien == 2 && "Bản tin ưu tiên"}
                    {item?.MucDoUuTien == 3 && "Bản tin thông thường"}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div className="Regular_13">
                    {item?.LoaiLinhVuc == 1 && "Thông tin chung"}
                    {item?.LoaiLinhVuc == 2 && "Thông tin đột xuất"}
                    {item?.LoaiLinhVuc == 3 &&
                      "Thông tin về đường lối, chủ trương của Đảng, chính sách, pháp luật của Nhà nước"}
                    {item?.LoaiLinhVuc == 4 &&
                      "Thông tin chỉ đạo, điều hành của cấp ủy, chính quyền cơ sở"}
                    {item?.LoaiLinhVuc == 5 &&
                      "Thông tin về kiến thức khoa học, kỹ thuật, kinh tế, văn hóa, xã hội"}
                    {item?.LoaiLinhVuc == 6 &&
                      "Thông tin về gương người tốt, việc tốt, điển hình tiên tiến"}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div style={{ minWidth: "150px" }}>
                    <p className="Regular_13 text-nowrap">
                      {item?.TacGia?.TenDayDu || ""}
                    </p>
                    <p className="Regular_13 text-nowrap">
                      {item?.TacGia?.Email || ""}
                    </p>
                    <p className="Regular_13 text-nowrap">
                      {item?.TacGia?.ButDanh || ""}
                    </p>
                  </div>
                ),
              },
              {
                comp: (
                  <p className="Regular_13 text-nowrap">
                    {item["area.name"] || item?.TenDiaBanTao}
                  </p>
                ),
              },
              {
                comp: !!item?.TrangThai && (
                  <div
                    className={`bulletin-status-badge bulletin-status-badge-${item?.TrangThai?.toLowerCase()}`}
                  >
                    {t(item?.TrangThai || "")}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.BanTinDienTuID);
                },
              },
              {
                comp: (
                  <div className="Manage" style={{ width: 80 }}>
                    <i
                      className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                      onClick={() => {
                        handleSetting(item.BanTinDienTuID);
                      }}
                    ></i>
                    <i
                      className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                      onClick={() => handleDelete(item)}
                    ></i>
                    {item.TrangThai === "APPROVED" && (
                      <i
                        className="fas fa-stop-circle cursor-pointer text-danger"
                        onClick={() => handleStop(item)}
                      ></i>
                    )}
                  </div>
                ),
              },
            ],
          };
        })}
        step={step}
        onRefresh={() => getDigitalSignageBulletins()}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white rounded-bottom"
        total={bulletinTotal}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
      <ConfirmModal
        ref={confirmModalRef}
        confirmButtonClass={"btn btn-danger"}
        confirmButtonText={"Xác Nhận"}
      />
    </>
  );
}

export default EBulletinTable;
