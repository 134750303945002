import React, { useEffect, useState } from "react";
import styled from "styled-components";
import bulletinApi from "../../../../api/bulletinApi";
import Loading from "../../../../components/Loading";
import { saveAs } from "file-saver";
import { buildURL } from "../../../../general/utils/Utils";
import useWebSocket from "react-use-websocket";
import { SelectSort } from "pages/Category/components/SelectSort";

const PrimaryButton = styled.button`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;

export default function ChooseSourceDocument({
  show,
  disableAll,
  document,
  onDocumentChange,
  documentVoice,
  onDocumentVoiceChange,
  ttsData,
  onTTSDataChange,
}) {
  const [voices, setVoices] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState();
  const [canAudioPlay, setCanAutoPlay] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [ttsHash, setTTSHash] = useState(null);
  const [voiceOptions, setVoiceOptions] = useState([]);

  const onTTSConvertSuccess = (ttsResponseData) => {
    if (ttsResponseData && ttsResponseData.voiceId && ttsResponseData.text) {
      if (ttsResponseData.status === "DONE" && ttsResponseData.audioContent) {
        onTTSDataChange(ttsResponseData);
        setShowLoading(false);
      } else if (
        ttsResponseData.status === "REQUESTING" &&
        ttsResponseData.requestId &&
        ttsResponseData.hash
      ) {
        let { requestId, hash } = ttsResponseData;
        setTTSHash(hash);
        setRequestId(requestId);
      }
    }
  };

  useEffect(() => {
    bulletinApi.getTTSVoices().then((response) => {
      // debugger;
      if (
        response?.data?.status === 0 &&
        Array.isArray(response?.data?.voices)
      ) {
        setVoices(response.data.voices);
        setCanAutoPlay(true);
      }
    });
  }, []);

  useEffect(() => {
    if (voices)
      setVoiceOptions(
        voices.map((item) => ({
          ...item,
          id: item.voiceId,
          value: item.voiceId,
          display: item.name,
        }))
      );
  }, [voices]);

  useEffect(() => {
    if (ttsData?.audioContent) {
      // debugger;
      setAudioUrl(buildURL(ttsData.audioContent));
    }
  }, [ttsData]);

  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    `wss://htttn.ipfm.vn/ws_v2`,
    {
      queryParams: {
        wsId: requestId,
      },
      onMessage: (message) => {
        if (message.data) {
          try {
            let data = JSON.parse(message.data);
            if (data?.hash === ttsHash) {
              onTTSConvertSuccess(data);
            }
          } catch (e) {}
        }
      },
      reconnectAttempts: 5,
      reconnectInterval: 3000,
      filter: () => false,
      shouldReconnect: (_closeEvent) => true,
      retryOnError: true,
      // onClose: () => console.log("closing"),
      // onError: (err) => console.log("error", err),
    }
  );

  return (
    <div
      style={{
        display: show ? "" : "none",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: 0,
          background: "rgba(255,255,255,0.8)",
          zIndex: 2,
          display: showLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          cursor: "wait",
        }}
      >
        <Loading />
      </div>
      <div
        className="d-flex align-items-center text-nowrap"
        style={{
          padding: "12px 0",
          marginBottom: 12,
        }}
      >
        <label
          htmlFor="select-document-voice"
          className="Regular_13 mb-0"
          style={{ marginRight: 12 }}
        >
          Lựa chọn giọng đọc
        </label>
        <SelectSort
          className="flex-grow-1"
          selections={voiceOptions}
          disable={disableAll}
          currentValue={
            _.find(voiceOptions, { voiceId: documentVoice })?.display ??
            "Chọn giọng"
          }
          onSelect={(item) => onDocumentVoiceChange(item.voiceId)}
        />
      </div>

      <div>
        <label
          htmlFor="document-content"
          className="Regular_13"
          style={{ marginBottom: 12 }}
        >
          Nội dung văn bản chuyển thành giọng nói
        </label>
        <textarea
          className="form-control"
          value={document}
          onChange={(e) => onDocumentChange(e.target.value)}
          rows={5}
          disabled={disableAll}
        />
      </div>

      <div
        style={{ background: "#F6F7FB", margin: -10, marginTop: 10 }}
        className="border-top"
      >
        <div style={{ padding: 10 }}>
          {/*<PrimaryButton className="btn btn-white border">*/}
          {/*  <i className="fas fa-play" style={{marginRight: 8}}/>*/}
          {/*  Nghe Thử*/}
          {/*</PrimaryButton>*/}
          <audio
            controls={!disableAll}
            className="w-100"
            key={audioUrl}
            // autoPlay={!!audioUrl && canAudioPlay && !disableAll}
          >
            <source src={audioUrl}></source>
          </audio>
        </div>
        <div
          className="d-flex justify-content-center border-top"
          style={{ padding: 10 }}
        >
          <button
            className="Bold_13 btn btn-white border"
            style={{ marginRight: 8 }}
            onClick={async (e) => {
              if (document && documentVoice) {
                try {
                  setShowLoading(true);
                  let ttsResponse = await bulletinApi.convertTTS(
                    document,
                    documentVoice
                  );

                  if (
                    ttsResponse?.data?.status === 0 &&
                    ttsResponse.data.tts &&
                    ttsResponse.data.tts.voiceId &&
                    ttsResponse.data.tts.text
                  ) {
                    onTTSConvertSuccess(ttsResponse.data.tts);
                  }
                  setShowLoading(false);
                } catch (error) {
                  setShowLoading(false);
                }
              }
            }}
            disabled={!(document && documentVoice) || disableAll}
          >
            <i className="far fa-repeat" style={{ marginRight: 8 }} />
            Chuyển Đổi
          </button>
          <PrimaryButton
            style={{ marginLeft: 8 }}
            disabled={!ttsData || disableAll}
            onClick={() => {
              if (audioUrl) {
                saveAs(audioUrl, audioUrl.split("/").pop());
              }
            }}
          >
            <i className="fas fa-arrow-to-bottom" />
            Tải Xuống
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
