import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styles from "./dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Chart_apex from "./component/Chart_apex.js";

// import Timeline from './component/Timeline.js';
import VietnamMap from "./component/Map/VietnamMap.jsx";
import QuangNinh from "./component/Map/QuangNinh.jsx";
import { useDispatch, useSelector } from "react-redux";
import AppPage from "components/AppPage";
import NavBar from "components/NavBar";
import { AppIcons, AppImages } from "general/constants/AppResource";
import AppCard from "components/AppCard";
import AppColors from "general/constants/AppColors";
import DonutChart from "./component/DonutChart";
import BulletinsCarousel from "./component/BulletinsCarousel";
import InfoComponent from "./component/InfoComponent";
import Carousel from "react-bootstrap/Carousel";
import ProgressBar from "react-bootstrap/ProgressBar";
import dashboardApi from "api/dashboardApi";
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import SelectArea from "components/SelectArea/SelectArea";
import areaApi from "api/areaApi";
import { setAllAreas } from "pages/AreaManager/areaSlice";

import DateRangePicker from "react-bootstrap-daterangepicker-maxspan";
import moment from "moment";
import classnames from "classnames/bind";
import Utils, { findAreaById } from "general/utils/Utils";
import HaiPhong from "./component/Map/HaiPhong";
import KhanhHoa from "./component/Map/KhanhHoa";

const cx = classnames.bind(styles);

DashboardHomeScreen.propTypes = {
  NumberDevices: PropTypes.number,
  DeviceData: PropTypes.array,
  MapData: PropTypes.array,
};
DashboardHomeScreen.defaultProps = {
  NumberDevices: 0,
  DeviceData: [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  MapData: [
    { areaIdLevel2: 26, deviceCount: 0 },
    { areaIdLevel2: 19, deviceCount: 0 },
    { areaIdLevel2: 77, deviceCount: 0 },
  ],
};
let myRef = React.createRef();
let mapRef = React.createRef();
let isRendering = false;
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function DashboardHomeScreen(props) {
  // MARK: --- get size of screens ---
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Area
  const [areasByUser, setAreasByUser] = useState([]);
  const [currentArea, setCurrentArea] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [currentAreaId, setCurrentAreaId] = useState(-1);
  const currentUser = useSelector((state) => state.auth.current);

  async function getAreas() {
    const res = await areaApi.getAreas();
    // debugger;
    const { MoTa, NoiDung } = res.data;
    if (MoTa == "success" && NoiDung) {
      setAreasByUser(NoiDung?.DanhSachKhuVuc);
      dispatch(setAllAreas(NoiDung?.DanhSachKhuVuc));
    }
  }
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.MaKhuVuc > 0 &&
      selectedArea?.MaKhuVuc != currentUser.MaKhuVuc
    ) {
      setSelectedArea(
        findAreaById(currentUser.MaKhuVuc ? currentUser.MaKhuVuc : -1)
      );
    }
  }, [currentUser, allAreas]);

  useEffect(() => {
    getAreas();
  }, []);
  useEffect(() => {
    let MaKhuVuc = selectedArea
      ? selectedArea.MaKhuVuc
      : currentUser
      ? currentUser.MaKhuVuc
      : null;
    if (MaKhuVuc) {
      setCurrentAreaId(MaKhuVuc);
      getDashboard(
        {
          startDate: dates[0].format("YYYY/MM/DD"),
          endDate: dates[1].clone().add(1, "days").format("YYYY/MM/DD"),
        },
        dates
      );
    }
  }, [selectedArea, currentUser.MaKhuVuc]);
  useEffect(() => {
    if (!currentArea) {
      setCurrentArea(
        areasByUser
          .filter((area) => area.MaKhuVuc == currentUser?.MaKhuVuc)
          .at(0)
      );
    }
  }, [areasByUser]);

  //MARK: ---Params ---
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { NumberDevices, DeviceData, MapData, Shows } = props;
  const [hDonut, setHDonut] = useState(0);
  const [wDonut, setWDonut] = useState(0);
  // const [indexCarousel, setIndexCarousel] = useState(0);
  const [dashboard, setDashboard] = useState();
  const [mapHeight, setMapHeight] = useState(0);
  const [dates, setDates] = useState([moment().subtract(6, "day"), moment()]);
  const [dateBetweens, setDateBetweens] = useState([]);
  const [numOfBulletinCount, setNumOfBulletinCount] = useState(DeviceData[0]);
  const [numOfTotalTime, setNumOfTotalTime] = useState(DeviceData[1]);
  const [numOfTotalSize, setNumOfTotalSize] = useState(DeviceData[2]);
  // MARK: --- Functions ---
  async function getDashboard(
    params,
    dates = [moment().subtract(6, "day"), moment()]
  ) {
    let MaKhuVuc = selectedArea
      ? selectedArea.MaKhuVuc
      : currentUser
      ? currentUser.MaKhuVuc
      : 0;
    params.MaKhuVuc = MaKhuVuc;

    const res = await dashboardApi.getDashboard(params);

    console.log("res", res);
    setDashboard(res?.data?.NoiDung);
    getDateBetweens(dates[0], dates[1], res?.data.NoiDung?.BanTin);
  }

  function returnBulletinCount(arrayOfCount) {
    if (arrayOfCount?.length == 0) {
      return 0;
    }
    let totalCount = 0;
    if (arrayOfCount?.length == 1) {
      totalCount = arrayOfCount[0].bulletinCount;
    } else {
      totalCount = arrayOfCount?.reduce(
        (prev, cur) => prev + cur.bulletinCount,
        0
      );
    }
    return totalCount;
  }

  function returnTotalSize(arrayOfSize) {
    if (!arrayOfSize?.length) {
      return "0 GB";
    }
    let totalSize = 0;
    if (arrayOfSize?.length == 1) {
      totalSize = Number(
        arrayOfSize[0].totalTime != null ? arrayOfSize[0].totalTime : 0
      );
    } else {
      totalSize = arrayOfSize?.reduce(
        (prev, cur) => prev + Number(cur.totalTime != null ? cur.totalTime : 0),
        0
      );
    }
    totalSize = totalSize * 16000;
    if (totalSize > 1000000000000) {
      return Math.ceil(totalSize / 1000000000).toString() + " GB";
    }
    if (totalSize > 1000000) {
      return Math.ceil(totalSize / 1000000).toString() + " MB";
    }
    if (totalSize > 1000) {
      return Math.ceil(totalSize / 1000).toString() + " KB";
    }
    if (totalSize <= 1000) {
      return totalSize.toString() + " Bytes";
    }
  }

  function returnTotalTime(arrayOfTime) {
    if (!arrayOfTime?.length) {
      return "0 " + t("Second");
    }
    let totalTime = 0;
    if (arrayOfTime?.length == 1) {
      totalTime = Number(arrayOfTime[0].totalTime);
    } else {
      totalTime = arrayOfTime?.reduce(
        (prev, cur) => prev + Number(cur.totalTime != null ? cur.totalTime : 0),
        0
      );
    }
    if (totalTime > 3600) {
      const Hours = Math.floor(totalTime / 3600);
      const Minutes = Math.floor(
        (totalTime - Math.floor(totalTime / 3600) * 3600) / 60
      );
      return (
        Hours.toString() +
        " " +
        t("Hour") +
        " " +
        Minutes.toString() +
        " " +
        t("Minute")
      );
    } else {
      return totalTime?.toString() + " " + t("Second");
    }
  }

  function getDateBetweens(startDate, stopDate, bulletinArray) {
    var dateArray = [],
      countArray = [],
      timeArray = [],
      sizeArray = [];
    let i = 0;
    // dateArray.push(startDate.format("DD"));
    // var currentDate = startDate.clone().add(1, 'days');
    var currentDate = startDate.clone();

    while (currentDate <= stopDate) {
      dateArray.push(currentDate.format("DD/MM"));
      if (bulletinArray && bulletinArray.length > 0) {
        if (currentDate.format("YYYY-MM-DD") === bulletinArray[i]?.date) {
          countArray.push(
            bulletinArray[i]?.bulletinCount != null
              ? bulletinArray[i]?.bulletinCount
              : 0
          );
          timeArray.push(
            bulletinArray[i]?.totalTime != null
              ? bulletinArray[i]?.totalTime
              : 0
          );
          sizeArray.push(
            bulletinArray[i]?.totalSize != null
              ? bulletinArray[i]?.totalSize
              : 0
          );
          if (i < bulletinArray.length) {
            i += 1;
          }
        } else {
          countArray.push(0);
          timeArray.push(0);
          sizeArray.push(0);
        }
      } else {
        countArray.push(0);
        timeArray.push(0);
        sizeArray.push(0);
      }
      currentDate = currentDate.add(1, "days");
    }
    // dateArray.pop();
    // dateArray.push(stopDate.format("DD"));

    // console.log(timeArray);
    // console.log(dateArray);
    setDateBetweens(dateArray);
    setNumOfBulletinCount(countArray);
    setNumOfTotalSize(sizeArray);
    setNumOfTotalTime(timeArray);
  }
  // MARK: --- Hooks ---
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (isRendering) {
        return;
      } else {
        isRendering = true;
      }
      setTimeout(() => {
        if (myRef.current) {
          setHDonut(myRef.current.offsetHeight);
          setWDonut(myRef.current.offsetWidth);
        }
        if (mapRef.current) {
          // console.log("Map height", mapRef.current.offsetHeight);
          setMapHeight(mapRef.current.offsetHeight);
        }
        isRendering = false;
      }, 1000);
    });
  }, []);
  useEffect(() => {
    if (myRef.current) {
      setHDonut(myRef.current.offsetHeight);
      setWDonut(myRef.current.offsetWidth);
    }
  }, [myRef]);
  useEffect(() => {
    getDashboard({
      startDate: moment().subtract(6, "day").format("YYYY/MM/DD"),
      endDate: moment().add(1, "day").format("YYYY/MM/DD"),
    });
  }, []);
  // useEffect(() => {getDateBetweens(moment().subtract(6,'day'), moment(), dashboard?.bulletins)},[])
  useEffect(() => {
    if (mapRef.current) {
      // console.log("Map height", mapRef.current.offsetHeight);
      setMapHeight(mapRef.current.offsetHeight);
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (dates[0] !== "--/--/----") {
      getDashboard(
        {
          startDate: dates[0].format("YYYY/MM/DD"),
          endDate: dates[1].clone().add(1, "days").format("YYYY/MM/DD"),
        },
        dates
      );
    }
  }, [dates]);

  return (
    <AppPage
      backgroundImage={AppImages.dashBoardBackground}
      headerTitle={t("appName")}
    >
      <NavBar navBarTitle={t("appName")}></NavBar>

      <div className="container-fluid p-0 w-100">
        <div className="row w-100 mx-0">
          <div className="col-xl-9 px-0">
            <div className="w-100">
              <div
                style={{
                  height:
                    windowSize.innerWidth >= 1200
                      ? mapHeight != 0
                        ? mapHeight
                        : "auto"
                      : "",
                }}
              >
                <div
                  className="Card border-0 w-100 h-100 d-flex mb-0"
                  style={{ background: "white" }}
                >
                  <div className="Card-body w-100 h-100 mb-5">
                    <div className="ml-5 mr-5 mt-3 d-flex flex-column flex-md-row justify-content-md-between">
                      <div
                        className="Bold_14 text-uppercase mt-3"
                        styles={{ height: "calc(1.5em + 1rem)" }}
                      >
                        {t("DeviceOverview")}
                      </div>
                      <div className="mt-4 mt-md-0 d-flex flex-row flex-wrap">
                        <SelectArea
                          className="mb-0 mr-md-6 w-100 w-md-200px w-xxl-250px w-xxxl-300px"
                          onSelect={(area) => {
                            setSelectedArea(area);
                          }}
                          rootAreaId={currentUser?.areaId}
                          selectedArea={selectedArea}
                        />
                        <div className="d-flex flex-row align-items-center w-100 w-md-fit-content mt-4 mt-md-0">
                          <DateRangePicker
                            minDate={moment().subtract(3, "month")}
                            maxDate={moment()}
                            // maxSpan = {{'days': 7}}
                            onApply={(event, picker) => {
                              if (
                                picker.startDate.format("DD/MM/YYYY") !=
                                picker.endDate.format("DD/MM/YYYY")
                              ) {
                                setDates([picker.startDate, picker.endDate]);
                              } else {
                                setDates([
                                  picker.startDate,
                                  picker.endDate.add(1, "day"),
                                ]);
                              }
                            }}
                          >
                            <button className="toggle-button position-relative">
                              <span
                                className="ml-2 mr-2"
                                style={{ color: "#4A5677", fontWeight: "600" }}
                              >
                                {" "}
                                {t("from")}
                              </span>
                              <span style={{ color: "#3ACE5A" }}>
                                {dates[0] !== "--/--/----"
                                  ? dates[0].format("DD/MM/YYYY")
                                  : "--/--/----"}
                              </span>
                              <span
                                className="mr-2 ml-2"
                                style={{ color: "#4A5677", fontWeight: "600" }}
                              >
                                {t("to")}
                              </span>
                              <span
                                className="mr-10"
                                style={{ color: "#E64135" }}
                              >
                                {dates[1] !== "--/--/----"
                                  ? dates[1].format("DD/MM/YYYY")
                                  : "--/--/----"}
                              </span>
                              <i
                                className="far fa-angle-down position-absolute top-50 translate-middle-y"
                                style={{ right: 12 }}
                              ></i>
                            </button>
                          </DateRangePicker>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mt-3 ml-2 mr-2"
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      <div className="col-lg-4 mt-6">
                        <div style={{ backgroundColor: "#f6f7fb" }}>
                          <Chart_apex
                            data={numOfBulletinCount}
                            style={{ height: "400px" }}
                            categories={dateBetweens}
                          ></Chart_apex>
                        </div>
                        <div>
                          <p
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              color: "#393874",
                              fontWeight: "600",
                              marginTop: "30px",
                            }}
                          >
                            {t("NumberOfBulletinsOverTime")}
                          </p>
                          <p
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              color: "#877F9B",
                              fontWeight: "500",
                            }}
                          >
                            {returnBulletinCount(dashboard?.BanTin)}{" "}
                            {" Bản tin"}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 mt-6">
                        <div style={{ backgroundColor: "#f6f7fb" }}>
                          <Chart_apex
                            data={numOfTotalTime}
                            colorStart="#F77698"
                            colorEnd="#E587D4"
                            style={{ height: "400px" }}
                            // categories={['01', '02', '03', '04', '05', '06', '07', '08']}
                            categories={dateBetweens}
                          ></Chart_apex>
                        </div>
                        <div>
                          <p
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              color: "#393874",
                              fontWeight: "600",
                              marginTop: "30px",
                            }}
                          >
                            {t("TotalHoursPlayed")}
                          </p>
                          <p
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              color: "#877F9B",
                              fontWeight: "500",
                            }}
                          >
                            {returnTotalTime(dashboard?.BanTin)}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 mt-6">
                        <div style={{ backgroundColor: "#f6f7fb" }}>
                          <Chart_apex
                            data={numOfTotalSize}
                            colorStart="#8B46DD"
                            colorEnd="#4248F5"
                            categories={dateBetweens}
                          ></Chart_apex>
                        </div>
                        <div>
                          <p
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              color: "#393874",
                              fontWeight: "600",
                              marginTop: "30px",
                            }}
                          >
                            {t("TotalBroadcastCapacity")}
                          </p>
                          <p
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              color: "#877F9B",
                              fontWeight: "500",
                            }}
                          >
                            {returnTotalSize(dashboard?.BanTin)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="row mb-6">
                  <div className="col-lg-4 mt-6">
                    <div
                      className="Card m-0 border-0 d-flex flex-column"
                      style={{ background: "white" }}
                    >
                      <div className="Card-body flex-grow-1 d-flex flex-column">
                        <div className="Card-title ml-5">
                          <div className="d-flex">
                            <div className="mr-auto ">
                              {t("BroadcastDevice")}
                            </div>
                          </div>
                        </div>
                        <div
                          ref={myRef}
                          className="Card-body flex-grow-1 d-flex flex-column mt-5"
                          style={{ aspectRatio: 1 }}
                        >
                          <DonutChart
                            wDonut={wDonut}
                            hDonut={hDonut}
                            wScreen={window.innerWidth}
                            donutData={dashboard?.CumLoa}
                          />
                        </div>
                        <div className="d-flex flex-column mt-5 ml-5 mr-5">
                          <div className="flex-grow-1 d-flex flex-row align-content-center">
                            <span
                              className="dot mt-1"
                              style={{ backgroundColor: "#3ACE5A" }}
                            ></span>
                            <span
                              className="flex-grow-1 ml-2"
                              style={{
                                color: "#9EA5BD",
                                fontFamily: "Inter",
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "50%",
                              }}
                            >
                              {t("PlayingDevice")}
                            </span>

                            <div className="flex-grow-1 d-flex flex-row">
                              <span
                                className="flex-grow-1"
                                style={{
                                  color: "#4A5677",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 700,
                                }}
                              >
                                {dashboard && dashboard?.CumLoa
                                  ? dashboard.CumLoa?.DangPhat
                                  : "0"}
                              </span>
                              {/* {console.log("WDonut", wDonut)} */}
                            </div>
                          </div>
                          <div className="flex-grow-1 d-flex flex-row align-content-center mt-3">
                            <span
                              className="dot mt-1"
                              style={{ backgroundColor: "#9EA5BD" }}
                            ></span>
                            <span
                              className="flex-grow-1 ml-2"
                              style={{
                                color: "#9EA5BD",
                                fontFamily: "Inter",
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "50%",
                              }}
                            >
                              {t("IdleDevice")}
                            </span>

                            <div className="flex-grow-1 d-flex flex-row">
                              <span
                                className="flex-grow-1"
                                style={{
                                  color: "#4A5677",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 700,
                                }}
                              >
                                {dashboard && dashboard.CumLoa
                                  ? dashboard.CumLoa.DangNghi
                                  : "0"}
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1 d-flex flex-row align-content-center mt-3 mb-5">
                            <span
                              className="dot mt-1"
                              style={{ backgroundColor: "#E54135" }}
                            ></span>
                            <span
                              className="flex-grow-1 ml-2"
                              style={{
                                color: "#9EA5BD",
                                fontFamily: "Inter",
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "50%",
                              }}
                            >
                              {t("NotConnectDevice")}
                            </span>

                            <div className="flex-grow-1 d-flex flex-row">
                              <span
                                className="flex-grow-1"
                                style={{
                                  color: "#4A5677",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 700,
                                }}
                              >
                                {dashboard && dashboard?.CumLoa
                                  ? dashboard.CumLoa?.TongSo -
                                    dashboard.CumLoa?.DangHoatDong
                                  : "0"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-6">
                    <div
                      className="Card m-0 border-0 d-flex flex-column"
                      style={{ background: "white" }}
                    >
                      <div className="Card-body flex-grow-1 d-flex flex-column">
                        <div className="Card-title ml-5">
                          <div className="d-flex">
                            <div className="mr-auto ">{t("BulletinBoard")}</div>
                          </div>
                        </div>
                        <div
                          ref={myRef}
                          className="Card-body flex-grow-1 d-flex flex-column mt-5"
                          style={{ aspectRatio: 1 }}
                        >
                          <DonutChart
                            wDonut={wDonut}
                            hDonut={hDonut}
                            wScreen={window.innerWidth}
                            donutData={dashboard?.BangTinDienTu}
                          />
                        </div>
                        <div className="d-flex flex-column mt-5 ml-5 mr-5">
                          <div className="flex-grow-1 d-flex flex-row align-content-center">
                            <span
                              className="dot mt-1"
                              style={{ backgroundColor: "#3ACE5A" }}
                            ></span>
                            <span
                              className="flex-grow-1 ml-2"
                              style={{
                                color: "#9EA5BD",
                                fontFamily: "Inter",
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "50%",
                              }}
                            >
                              {t("PlayingDevice")}
                            </span>

                            <div className="flex-grow-1 d-flex flex-row">
                              <span
                                className="flex-grow-1"
                                style={{
                                  color: "#4A5677",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 700,
                                }}
                              >
                                {dashboard?.BangTinDienTu?.DangPhat ?? "0"}
                              </span>
                              {/* {console.log("WDonut", wDonut)} */}
                            </div>
                          </div>
                          <div className="flex-grow-1 d-flex flex-row align-content-center mt-3">
                            <span
                              className="dot mt-1"
                              style={{ backgroundColor: "#9EA5BD" }}
                            ></span>
                            <span
                              className="flex-grow-1 ml-2"
                              style={{
                                color: "#9EA5BD",
                                fontFamily: "Inter",
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "50%",
                              }}
                            >
                              {t("IdleDevice")}
                            </span>

                            <div className="flex-grow-1 d-flex flex-row">
                              <span
                                className="flex-grow-1"
                                style={{
                                  color: "#4A5677",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 700,
                                }}
                              >
                                {dashboard?.BangTinDienTu?.DangNghi ?? "0"}
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1 d-flex flex-row align-content-center mt-3 mb-5">
                            <span
                              className="dot mt-1"
                              style={{ backgroundColor: "#E54135" }}
                            ></span>
                            <span
                              className="flex-grow-1 ml-2"
                              style={{
                                color: "#9EA5BD",
                                fontFamily: "Inter",
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "50%",
                              }}
                            >
                              {t("NotConnectDevice")}
                            </span>

                            <div className="flex-grow-1 d-flex flex-row">
                              <span
                                className="flex-grow-1"
                                style={{
                                  color: "#4A5677",
                                  fontSize: 14,
                                  fontFamily: "Inter",
                                  fontWeight: 700,
                                }}
                              >
                                {dashboard && dashboard?.BangTinDienTu
                                  ? dashboard.BangTinDienTu.TongSo -
                                    dashboard.BangTinDienTu.DangHoatDong
                                  : "0"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mt-6">
                    <BulletinsCarousel
                      dates={dates}
                      currentAreaId={currentAreaId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 px-0">
            <div
              className={`${cx(window.innerWidth < 1200 ? "" : "ml-3 ")} row`}
            >
              <div ref={mapRef} className="col-xl-12 col-md-8">
                <div
                  className="Card border-0 h-100"
                  style={{ background: "white", marginBottom: "0" }}
                >
                  <div className="Card-body ">
                    <div className="Card-title ml-5">
                      <b>{t("DeviceCountMap")}</b>
                    </div>
                    <div
                      className="mt-3 "
                      style={{ backgroundColor: AppColors.Background }}
                    >
                      {/* <VietnamMap/> */}
                      {/* <QuangNinh
                        mapData={dashboard?.deviceMap}
                        windowWidth={window.innerWidth}
                      /> */}
                      {/* <HaiPhong
                        mapData={dashboard?.deviceMap}
                        windowWidth={window.innerWidth}
                      /> */}
                      <KhanhHoa
                        mapData={dashboard?.deviceMap}
                        windowWidth={window.innerWidth}
                      />
                    </div>
                  </div>
                  <div className="Card-footer mt-2">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%", background: "#CDF7D8" }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%", background: "#BCECC8" }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%", background: "#A8E2B7" }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%", background: "#61D088" }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: "20%", background: "#32B761" }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={` d-flex flex-column col-xl-12 col-md-4 ${cx(
                  window.innerWidth < 1200 && window.innerWidth >= 768
                    ? ""
                    : "mt-6"
                )}`}
              >
                <InfoComponent
                  title={t("nRadio")}
                  info={dashboard?.DaiTruyenThanh?.toString()}
                  icon={AppIcons.icRadio}
                ></InfoComponent>
                <InfoComponent
                  title={t("nRadiotor")}
                  info={dashboard?.NguoiDung?.toString()}
                  icon={AppIcons.icRadiotor}
                ></InfoComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppPage>
  );
}

export default DashboardHomeScreen;
