import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import DeviceDetailScreen from "pages/DeviceDetail/screens/DeviceDetailScreen";
import IpradioDeviceManagerScreen from "./screens/IpradioDeviceManagerScreen";
import DigitalSignageDeviceManagerScreen from "./screens/DigitalSignageDeviceManagerScreen";

function DeviceManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/ipradio`}
        component={IpradioDeviceManagerScreen}
      />
      <Route
        exact
        path={`${match.url}/digitalsignage`}
        component={DigitalSignageDeviceManagerScreen}
      />
      {/* <Route
        exact
        path={`${match.url}/speaker`}
        component={DeviceManagerScreen}
      /> */}
      {/* <Route exact path={`${match.url}/bulletin-board`} component={BulletinBoardManagerScreen} /> */}
      <Route
        exact
        path={`${match.url}/:deviceType/:id`}
        component={DeviceDetailScreen}
      />
      {/* <Route exact path={`${match.url}/:deviceType/configure/:ids`} component={DeviceConfiguredScreen} />   */}
      <Route component={NotFound} />
    </Switch>
  );
}

export default DeviceManager;
