// api/axiosClient.js
import axios from "axios";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";
import ToastHelper from "general/helpers/ToastHelper";
// import UserHelper from "general/helpers/UserHelper";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const sTag = "[AxiosClient]";

// Cai dat config mac dinh cho http request
// Tham khao: `https://github.com/axios/axios#request-config`
// de xem chi tiet
// console.log(process.env.REACT_APP_API_URL, process.env.REACT_APP_BASE_URL);
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  console.log(
    `${sTag} - ${config.baseURL}${config.url}, ${config.method}, ${
      config.method === "post" || config.method === "put"
        ? JSON.stringify(config.data)
        : JSON.stringify(config.params)
    }`
  );
  console.log(`${sTag} - headers: ${JSON.stringify(config.headers.common)}`);
  const accessToken = Global.accessToken;

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return { data: response.data, headers: response.headers };
    }

    return response;
  },
  async (error) => {
    console.log({ error });
    // Bat loi xay ra
    // if (!error.response) {
    //   ToastHelper.showError("Không thể kết nối đến server");
    // }
    if (error.response.status === 423) {
      Global.accessToken = null;
      Global.refreshToken = null;
      localStorage.removeItem(PreferenceKeys.accessToken);
      localStorage.removeItem(PreferenceKeys.refreshToken);
      ToastHelper.showError("Tài khoản đã bị khoá");
      // window.location.reload();
      return error;
    }
    // debugger;
    const originalConfig = error.config;
    if (originalConfig.url == "/oauth/refreshtoken" && error.response) {
      // debugger;
      if (originalConfig._retry) {
        return Promise.reject();
      }
      if (error.response.status === 400) {
        originalConfig._retry = true;
        Global.accessToken = null;
        Global.refreshToken = null;
        localStorage.removeItem(PreferenceKeys.accessToken);
        localStorage.removeItem(PreferenceKeys.refreshToken);
        window.location.reload();
        return Promise.reject();
      }
    }
    if (
      originalConfig.url !== "/oauth/login" &&
      originalConfig.url !== "/oauth/refreshtoken" &&
      error.response
    ) {
      // Access Token was expired
      if (
        error.response.status === 401 &&
        !originalConfig._retry &&
        Global.refreshToken &&
        Global.refreshToken.length > 0 &&
        Global.accessToken &&
        Global.accessToken.length > 0
      ) {
        originalConfig._retry = true;

        try {
          const config = {
            headers: { Authorization: `Bearer ${Global.accessToken}` },
          };
          const formData = new FormData();
          formData.append("refreshToken", Global.refreshToken);
          const rs = await axiosClient.post(
            "/oauth/refreshtoken",
            formData,
            config
          );
          // debugger;
          const { MoTa } = rs?.data;
          const { Token } = rs?.data?.NoiDung;
          if (Token && MoTa == "ThanhCong") {
            // debugger;
            console.log(accessToken, Global.accessToken);
            Global.accessToken = Token;
            localStorage.setItem(PreferenceKeys.accessToken, Token);
            originalConfig.headers.Authorization = `Bearer ${Token}`;
            return axiosClient(originalConfig);
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    let errorMessage = null;
    const response = error.response;

    if (response && response.data) {
      const data = response.data;
      const { result, reason } = data;
      if (result === "failed" && reason) {
        errorMessage = reason;
      }
    }
    if (errorMessage) {
      ToastHelper.showError(errorMessage);
    }
    return { data: response.data, headers: response.headers };
  }
);

// Update base url
const updateAxiosBaseURL = (baseUrl) => {
  axiosClient.defaults.baseURL = baseUrl;
};

// Update access token
const updateAxiosAccessToken = (accessToken) => {
  axiosClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accessToken}`;
};

// Remove access token
const removeAxiosAccessToken = () => {
  delete axiosClient.defaults.headers.common["Authorization"];
};

(() => {
  // const isTokenValid = UserHelper.checkAccessTokenValid();
  // if (isTokenValid) {
  //   const token = localStorage.getItem(PreferenceKeys.accessToken);
  //   updateAxiosAccessToken(token);
  // } else {
  //   UserHelper.signOut();
  // }
})();

export { updateAxiosAccessToken, removeAxiosAccessToken, updateAxiosBaseURL };

export default axiosClient;
